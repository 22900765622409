import { Founder } from './types';

export const founders: Founder[] = [
  {
    id: 'geetha-narasimham',
    name: 'Geetha (Rita) Narasimham',
    role: 'Co-Founder',
    image: 'https://www.stsworldwide.us/img/Geetha-Narasimham-STS-Worldwide-Founder.jpg?auto=format&fit=crop&q=80',
    location: 'Atlanta, Georgia',
    education: 'BS in Applied Sciences, State University of New York, Albany',
    bio: `The (Late) Mrs. Geetha Narasimham's (Rita) family hails from Coimbatore, Tamil Nadu, India. She was born in Ankara, Turkey and raised at Coimbatore, India. She emigrated to the United States back in 1969 as a middle school student and completed her high school at Ardsley, Westchester County NY.\n\nWhile studying at State University of New York at Albany, New York, (1975) she was introduced to Mr. PundiNarasimham, a Ph.D. student of Materials Sciences at Washington State University by her aunt, Indira. This strictly phone only relationship later on bloomed into Pundi proposing to Geetha to marry her in the summer of 1977.\n\nShe holds a BS in Applied Sciences from the State University of New York, Albany, NY and an associate degree in Computer Science from Gwinnett Institute of Technology, Atlanta, Georgia. She is a prodigy in classical (vocal) music from Coimbatore.`,
    achievements: [
      'Pioneer in starting the Atlanta Hindu Temple',
      'Founder of the Georgia Tamil Sangam',
      'Donated 5 gallons of blood to RED CROSS as an undergraduate student',
      'One of only two women CEOs of Indian origin in USA (1996)'
    ]
  },
  {
    id: 'pundi-narasimham',
    name: 'Pundi Narasimham',
    role: 'Founder & Chief Mentor',
    image: 'https://www.stsworldwide.us/img/STS-Worldwide-Founder-and-Chief-Mentor-Pundi-Narasimham.jpg?auto=format&fit=crop&q=80',
    location: 'Atlanta, Georgia',
    education: 'PhD in Materials Sciences, Washington State University',
    bio: `US Citizen and a serial entrepreneur from 1982, having built and sold many companies in the last few decades in North America and Asia Pacific region covering India, Malaysia and Philippines. He is a multi-faceted and outstanding resourceful professional with hands-on experience in global Marketing, Global Talent acquisition & retention, Fund raising, M&A and Liaison with Government and Trade bodies.\n\nPundi Narasimham is well-connected and has travelled across continents with a global mindset. He is an expert in providing growth strategies to the Top Management, strategic & hands-on management support to analyze the business status and suggest appropriate measures to increase top line and bottom line of the Company.`,
    achievements: [
      'Led trade delegation from Georgia to Silicon Valley of India (Bangalore)',
      'Nominated as International Business Development Representative by Fulton County',
      'Established US County Trade Office in Bangalore',
      'Instrumental in US Fulton County participation in IT.com event'
    ]
  },
  {
    id: 'deepak-narasimham',
    name: 'Deepak Narasimham',
    role: 'President, Global Head of M&A',
    image: 'https://www.stsworldwide.us/img/STS-Worldwide-Founder-Deepak-Narasimham.jpg?auto=format&fit=crop&q=80',
    location: 'Atlanta, Georgia',
    education: "Bachelor's degree in Business Administration",
    bio: `Deepak Narasimham is a seasoned IT professional with a diversified background in technology and finance. He has over 10 years of experience in the global information technology industry, M&A, and investment banking.\n\nDeepak has been part of his parent's privately owned international IT consulting firm since his high school days. He closely worked with his parents both in strategic and tactical management of the company to expand its operations to Canada, Malaysia, Philippines, apart from India.`,
    achievements: [
      'Led multiple merger and acquisition deals since 2006',
      'Expanded company operations to Canada, Malaysia, and Philippines',
      'Key role in company merger with Core Education & Technologies',
      'Strategic management of international operations'
    ]
  }
];