import React from 'react';
import InsightsHero from '../components/insights/InsightsHero';
import InsightsList from '../components/insights/InsightsList';
import FeaturedInsight from '../components/insights/FeaturedInsight';
import ResearchInsights from '../components/thought-leadership/ResearchInsights';
import CaseStudies from '../components/insights/CaseStudies';
import NewsletterCTA from '../components/insights/NewsletterCTA';

const Insights = () => {
  return (
    <div className="min-h-screen">
      <InsightsHero />
      <FeaturedInsight />

      <CaseStudies />

      <NewsletterCTA />
    </div>
  );
};

export default Insights;