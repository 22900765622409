import React from 'react';
import { X, Building2, TrendingUp, Users, BarChart, Globe2 } from 'lucide-react';

interface CaseStudyModalProps {
  isOpen: boolean;
  onClose: () => void;
  caseStudy: {
    title: string;
    client: string;
    image: string;
    result: string;
    description: string;
  };
}

const CaseStudyModal: React.FC<CaseStudyModalProps> = ({ isOpen, onClose, caseStudy }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header Image */}
        <div className="relative h-64">
          <img 
            src={caseStudy.image}
            alt={caseStudy.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 bg-white/90 p-2 rounded-full hover:bg-white transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
          <div className="absolute bottom-6 left-6">
            <h2 className="text-3xl font-bold text-white mb-2">{caseStudy.title}</h2>
            <div className="flex items-center text-white/80">
              <Building2 className="h-5 w-5 mr-2" />
              {caseStudy.client}
            </div>
          </div>
        </div>

        <div className="p-8">
          {/* Key Metrics */}
          <div className="grid grid-cols-4 gap-6 mb-8">
            <div className="bg-blue-50 p-4 rounded-xl">
              <TrendingUp className="h-6 w-6 text-blue-600 mb-2" />
              <div className="text-2xl font-bold text-gray-900">45%</div>
              <div className="text-sm text-gray-600">Efficiency Gain</div>
            </div>
            <div className="bg-blue-50 p-4 rounded-xl">
              <Users className="h-6 w-6 text-blue-600 mb-2" />
              <div className="text-2xl font-bold text-gray-900">100+</div>
              <div className="text-sm text-gray-600">Team Members</div>
            </div>
            <div className="bg-blue-50 p-4 rounded-xl">
              <BarChart className="h-6 w-6 text-blue-600 mb-2" />
              <div className="text-2xl font-bold text-gray-900">{caseStudy.result}</div>
              <div className="text-sm text-gray-600">ROI Achieved</div>
            </div>
            <div className="bg-blue-50 p-4 rounded-xl">
              <Globe2 className="h-6 w-6 text-blue-600 mb-2" />
              <div className="text-2xl font-bold text-gray-900">5+</div>
              <div className="text-sm text-gray-600">Countries</div>
            </div>
          </div>

          {/* Challenge & Solution */}
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">The Challenge</h3>
              <p className="text-gray-600">
                {caseStudy.description}
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Our Solution</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <span className="h-2 w-2 bg-blue-600 rounded-full mt-2 mr-3" />
                  <span className="text-gray-600">Comprehensive analysis and strategy development</span>
                </li>
                <li className="flex items-start">
                  <span className="h-2 w-2 bg-blue-600 rounded-full mt-2 mr-3" />
                  <span className="text-gray-600">Implementation of cutting-edge technologies</span>
                </li>
                <li className="flex items-start">
                  <span className="h-2 w-2 bg-blue-600 rounded-full mt-2 mr-3" />
                  <span className="text-gray-600">Process optimization and automation</span>
                </li>
                <li className="flex items-start">
                  <span className="h-2 w-2 bg-blue-600 rounded-full mt-2 mr-3" />
                  <span className="text-gray-600">Training and change management support</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Results & Impact */}
          <div>
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Results & Impact</h3>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-gray-50 p-6 rounded-xl">
                <div className="text-2xl font-bold text-blue-600 mb-2">{caseStudy.result}</div>
                <p className="text-gray-600">Efficiency improvement across operations</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-xl">
                <div className="text-2xl font-bold text-blue-600 mb-2">30%</div>
                <p className="text-gray-600">Cost reduction achieved</p>
              </div>
              <div className="bg-gray-50 p-6 rounded-xl">
                <div className="text-2xl font-bold text-blue-600 mb-2">24/7</div>
                <p className="text-gray-600">Operational availability</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyModal;