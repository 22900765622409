import React, { useState } from 'react';
import { Calendar, User, ArrowRight, ChevronRight, ChevronLeft } from 'lucide-react';
import ArticleModal from './modals/ArticleModal';

const featuredArticles = [
  {
    title: 'The Future of Digital Transformation: AI and Beyond',
    category: 'Digital Innovation',
    author: 'Suresh',
    date: 'Mar 15, 2024',
    image: 'https://www.stsworldwide.us/img/photo-1451187580459-43490279c0fa.avif?auto=format&fit=crop&q=80',
    excerpt: 'Explore how artificial intelligence is reshaping business operations and decision-making processes in 2024 and beyond.'
  },
  {
    title: 'Global M&A Trends: Navigating Cross-Border Complexities',
    category: 'M&A Insights',
    author: 'Deepak Narasimham',
    date: 'Mar 18, 2024',
    image: 'https://www.stsworldwide.us/img/photo-1486406146926-c627a92ad1ab.avif?auto=format&fit=crop&q=80',
    excerpt: 'Analysis of emerging trends in cross-border M&A, regulatory challenges, and strategies for successful international deals.'
  }
];

const FeaturedInsight = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
  const currentArticle = featuredArticles[currentArticleIndex];

  const nextArticle = () => {
    setCurrentArticleIndex((prev) => 
      prev === featuredArticles.length - 1 ? 0 : prev + 1
    );
  };

  const previousArticle = () => {
    setCurrentArticleIndex((prev) => 
      prev === 0 ? featuredArticles.length - 1 : prev - 1
    );
  };

  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gradient-to-r from-blue-50 to-violet-50 rounded-3xl overflow-hidden">
          <div className="grid lg:grid-cols-2 gap-8 p-8">
            <div className="space-y-6">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                {currentArticle.category}
              </span>
              <h2 className="text-3xl font-bold text-gray-900">
                {currentArticle.title}
              </h2>
              <p className="text-lg text-gray-600">
                {currentArticle.excerpt}
              </p>
              
              <div className="flex items-center space-x-6 text-sm text-gray-500">
                <div className="flex items-center">
                  <User className="h-4 w-4 mr-2" />
                  {currentArticle.author}
                </div>
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-2" />
                  {currentArticle.date}
                </div>
              </div>

              <button 
                onClick={() => setIsModalOpen(true)}
                className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Read Article
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
            </div>
            
            <div className="relative">
              <img 
                src={currentArticle.image}
                alt={currentArticle.title}
                className="rounded-xl shadow-lg object-cover w-full h-full"
              />
              
              {/* Navigation Buttons */}
              <div className="absolute inset-y-0 left-0 right-0 flex items-center justify-between">
                <button 
                  onClick={previousArticle}
                  className="p-2 bg-white/80 rounded-full shadow-lg hover:bg-white transition-colors ml-4"
                >
                  <ChevronLeft className="h-6 w-6 text-gray-800" />
                </button>
                <button 
                  onClick={nextArticle}
                  className="p-2 bg-white/80 rounded-full shadow-lg hover:bg-white transition-colors mr-4"
                >
                  <ChevronRight className="h-6 w-6 text-gray-800" />
                </button>
              </div>

              {/* Article Navigation Dots */}
              <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                {featuredArticles.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentArticleIndex(index)}
                    className={`w-2 h-2 rounded-full transition-all duration-300 ${
                      currentArticleIndex === index 
                        ? 'bg-blue-600 w-6' 
                        : 'bg-white/80 hover:bg-white'
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ArticleModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        article={currentArticle}
      />
    </section>
  );
};

export default FeaturedInsight;