import React from 'react';
import { Service } from '../types';
import ServiceFeatures from './ServiceFeatures';
import ServiceMetrics from './ServiceMetrics';
import ServiceCases from './ServiceCases';
import { ArrowRight } from 'lucide-react';

interface ServiceDetailsProps {
  service: Service;
  onLearnMore: () => void;
}

const ServiceDetails: React.FC<ServiceDetailsProps> = ({ service, onLearnMore }) => {
  return (
    <div className="bg-white p-8 rounded-2xl shadow-lg">
      <div className="mb-8">
        <div className={`inline-flex p-4 rounded-xl bg-gradient-to-r ${service.color} mb-6 text-white`}>
          <service.icon className="h-8 w-8" />
        </div>
        
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          {service.title}
        </h2>
        
        <p className="text-lg text-gray-600 mb-8">
          {service.description}
        </p>

        <ServiceMetrics metrics={service.metrics} />
      </div>

      <ServiceFeatures features={service.features} />
      <ServiceCases cases={service.cases} />

      <div className="mt-8 flex justify-between items-center">
        <button 
          onClick={onLearnMore}
          className="inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
        >
          Learn More
          <ArrowRight className="ml-2 h-5 w-5" />
        </button>

        <div className="flex gap-4">
          {service.benefits.map((benefit, index) => (
            <span 
              key={index}
              className="px-3 py-1 bg-blue-50 text-blue-600 rounded-full text-sm"
            >
              {benefit}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;