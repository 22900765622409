import React from 'react';
import { Cookie, Info, Settings, Shield } from 'lucide-react';

const CookiePolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <Cookie className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Cookie Policy</h1>
          <p className="text-xl text-gray-600">
            Understanding how we use cookies to improve your experience.
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <div className="prose prose-blue max-w-none">
            <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4">
              <Info className="h-6 w-6 mr-2 text-blue-600" />
              What Are Cookies?
            </h2>
            <p className="text-gray-600 mb-6">
              Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience and understand how you interact with our content.
            </p>

            <h3 className="flex items-center text-xl font-semibold text-gray-900 mb-4">
              <Settings className="h-6 w-6 mr-2 text-blue-600" />
              Types of Cookies We Use
            </h3>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li>Essential cookies for website functionality</li>
              <li>Analytics cookies to improve our service</li>
              <li>Preference cookies to remember your settings</li>
              <li>Marketing cookies for targeted advertising</li>
            </ul>

            <h3 className="flex items-center text-xl font-semibold text-gray-900 mb-4">
              <Shield className="h-6 w-6 mr-2 text-blue-600" />
              Your Cookie Choices
            </h3>
            <p className="text-gray-600 mb-6">
              You can control and/or delete cookies as you wish. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed.
            </p>

            {/* Add more sections as needed */}
          </div>
        </div>

        <div className="text-center text-gray-600">
          <p>Last updated: March 15, 2024</p>
          <p>For questions about our cookie policy, please contact privacy@stsworldwide.com</p>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;