import React, { useState, useEffect } from 'react';
import { ArrowLeft, ArrowRight, Building2, TrendingUp, Globe2, Target, ChevronRight } from 'lucide-react';
import AnimatedBackground from '../ui/AnimatedBackground';
import { motion, AnimatePresence } from 'framer-motion';

const slides = [
  {
    title: "Strategic M&A Advisory",
    subtitle: "Transforming Business Through Strategic Excellence",
    description: "Your trusted partner in mergers, acquisitions, and strategic growth initiatives.",
    stats: [
      { number: '50+', label: 'Global Clients' },
      { number: '10+', label: 'Countries' },
      { number: '30+', label: 'Years Experience' },
      { number: '95%', label: 'Success Rate' }
    ],
    icon: Building2,
    image: "https://www.stsworldwide.us/img/photo-1486406146926-c627a92ad1ab.avif?auto=format&fit=crop&q=80",
    color: "from-blue-600 to-indigo-600"
  },
  {
    title: "Cross-Border Transactions",
    subtitle: "Global Reach, Local Expertise",
    description: "Specialized in complex international M&A deals and market expansion strategies.",
    stats: [
      { number: '$2B+', label: 'Deal Value' },
      { number: '200+', label: 'Transactions' },
      { number: '15+', label: 'Markets' },
      { number: '98%', label: 'Client Satisfaction' }
    ],
    icon: Globe2,
    image: "https://www.stsworldwide.us/img/photo-1526304640581-d334cdbbf45e.avif?auto=format&fit=crop&q=80",
    color: "from-violet-600 to-purple-600"
  },
  {
    title: "Value Creation",
    subtitle: "Maximizing Transaction Outcomes",
    description: "Expert guidance in deal structuring, valuation, and post-merger integration.",
    stats: [
      { number: '40%', label: 'Avg Premium' },
      { number: '3x', label: 'Value Multiple' },
      { number: '$500M+', label: 'Synergies' },
      { number: '90%', label: 'Integration Success' }
    ],
    icon: TrendingUp,
    image: "https://www.stsworldwide.us/img/photo-1507679799987-c73779587ccf.avif?auto=format&fit=crop&q=80",
    color: "from-emerald-600 to-teal-600"
  },
  {
    title: "Industry Expertise",
    subtitle: "Sector-Focused Solutions",
    description: "Deep industry knowledge across technology, healthcare, manufacturing, and more.",
    stats: [
      { number: '20+', label: 'Industries' },
      { number: '150+', label: 'Experts' },
      { number: '25+', label: 'Awards' },
      { number: '100%', label: 'Commitment' }
    ],
    icon: Target,
    image: "https://www.stsworldwide.us/img/photo-1486406146926-c627a92ad1ab.avif?auto=format&fit=crop&q=80",
    color: "from-amber-600 to-orange-600"
  }
];

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setDirection(1);
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 7000);
    return () => clearInterval(timer);
  }, []);

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection: number) => {
    setDirection(newDirection);
    setCurrentSlide((prev) => (prev + newDirection + slides.length) % slides.length);
  };

  return (
    <div className="relative min-h-screen flex items-center overflow-hidden">
      <AnimatedBackground />
      
      <div className="relative z-20 w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
          <div className="relative h-[600px]">
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={currentSlide}
                custom={direction}
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: "spring", stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 }
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                  const swipe = swipePower(offset.x, velocity.x);
                  if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                  } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                  }
                }}
                className="absolute w-full"
              >
                <div className="grid lg:grid-cols-2 gap-12 items-center">
                  <div className="space-y-8">
                    <div>
                      <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        className="inline-flex items-center px-4 py-2 rounded-full bg-gradient-to-r from-white/10 to-white/5 backdrop-blur-md text-white text-sm mb-6"
                      >
                        {React.createElement(slides[currentSlide].icon, {
                          className: "h-4 w-4 mr-2"
                        })}
                        {slides[currentSlide].subtitle}
                      </motion.div>
                      
                      <motion.h1 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                        className="text-5xl md:text-6xl font-bold mb-6"
                      >
                        <span className="text-white">{slides[currentSlide].title.split(' ')[0]}</span>{' '}
                        <span className={`bg-gradient-to-r ${slides[currentSlide].color} text-transparent bg-clip-text`}>
                          {slides[currentSlide].title.split(' ').slice(1).join(' ')}
                        </span>
                      </motion.h1>
                      
                      <motion.p 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4 }}
                        className="text-xl text-gray-200 leading-relaxed"
                      >
                        {slides[currentSlide].description}
                      </motion.p>
                    </div>

                    <motion.div 
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5 }}
                      className="grid grid-cols-2 gap-4"
                    >
                      {slides[currentSlide].stats.map((stat, index) => (
                        <div
                          key={index}
                          className="group bg-white/10 backdrop-blur-md p-6 rounded-2xl hover:bg-white/20 transition-all duration-300"
                        >
                          <div className="text-2xl font-bold text-white mb-1 group-hover:scale-110 transition-transform">
                            {stat.number}
                          </div>
                          <div className="text-blue-200 text-sm">{stat.label}</div>
                        </div>
                      ))}
                    </motion.div>
                  </div>

                  <motion.div 
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    className="relative hidden lg:block"
                  >
                    <div className="relative rounded-2xl overflow-hidden aspect-square">
                      <div className="absolute inset-0 bg-gradient-to-br from-black/30 to-black/10" />
                      <img
                        src={slides[currentSlide].image}
                        alt={slides[currentSlide].title}
                        className="w-full h-full object-cover"
                      />
                      <div className={`absolute inset-0 bg-gradient-to-br ${slides[currentSlide].color} opacity-30`} />
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Navigation */}
          <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between px-4">
            <button
              onClick={() => paginate(-1)}
              className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors group"
            >
              <ArrowLeft className="h-6 w-6 text-white group-hover:-translate-x-1 transition-transform" />
            </button>
            
            <div className="flex items-center space-x-4">
              {slides.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`relative h-2 transition-all duration-300 ${
                    currentSlide === index ? 'w-8 bg-white' : 'w-2 bg-white/50'
                  } rounded-full`}
                />
              ))}
            </div>
            
            <button
              onClick={() => paginate(1)}
              className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors group"
            >
              <ArrowRight className="h-6 w-6 text-white group-hover:translate-x-1 transition-transform" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;