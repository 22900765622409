import React from 'react';
import { ServiceCase } from '../types';
import { Building2, TrendingUp } from 'lucide-react';

interface ServiceCasesProps {
  cases: ServiceCase[];
}

const ServiceCases: React.FC<ServiceCasesProps> = ({ cases }) => {
  return (
    <div className="mb-8">
      <h3 className="text-xl font-semibold text-gray-900 mb-6">Success Stories</h3>
      <div className="grid md:grid-cols-2 gap-6">
        {cases.map((caseStudy, index) => (
          <div key={index} className="bg-gray-50 p-6 rounded-xl">
            <div className="flex items-center mb-4">
              <Building2 className="h-5 w-5 text-blue-600 mr-2" />
              <span className="text-sm text-gray-500">{caseStudy.industry}</span>
            </div>
            <h4 className="font-semibold text-gray-900 mb-2">{caseStudy.title}</h4>
            <p className="text-sm text-gray-600 mb-4">{caseStudy.description}</p>
            <div className="grid grid-cols-2 gap-4">
              {caseStudy.results.map((result, idx) => (
                <div key={idx} className="flex items-center text-green-600">
                  <TrendingUp className="h-4 w-4 mr-1" />
                  <div>
                    <div className="text-sm font-medium">{result.value}</div>
                    <div className="text-xs text-gray-500">{result.metric}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCases;