import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import CaseStudyModal from './modals/CaseStudyModal';

const caseStudies = [
  {
    title: 'Digital Transformation Success',
    client: 'Global Manufacturing Corp',
    image: 'https://www.stsworldwide.us/img/photo-1451187580459-43490279c0fa.avif?auto=format&fit=crop&q=80',
    result: '45% Efficiency Increase',
    description: 'Implemented end-to-end digital transformation strategy resulting in significant operational improvements.'
  },
  {
    title: 'Market Expansion Strategy',
    client: 'Tech Solutions Inc',
    image: 'https://www.stsworldwide.us/img/photo-1460925895917-afdab827c52f.avif?auto=format&fit=crop&q=80',
    result: '200% Revenue Growth',
    description: 'Successful expansion into APAC markets with localized strategy and partnership development.'
  }
];

const CaseStudyPreview = () => {
  const [selectedCase, setSelectedCase] = useState<typeof caseStudies[0] | null>(null);

  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Client Success Stories
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Real results from our strategic partnerships
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {caseStudies.map((study, index) => (
            <div 
              key={index}
              className="group bg-white rounded-2xl shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <div className="relative h-48">
                <img 
                  src={study.image}
                  alt={study.title}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-4 left-4 text-white">
                  <div className="text-sm mb-1">{study.client}</div>
                  <div className="font-semibold">{study.result}</div>
                </div>
              </div>

              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {study.title}
                </h3>
                <p className="text-gray-600 mb-4">
                  {study.description}
                </p>
                <button 
                  onClick={() => setSelectedCase(study)}
                  className="flex items-center text-blue-600 font-medium group-hover:text-blue-700"
                >
                  Read Full Case Study
                  <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedCase && (
        <CaseStudyModal
          isOpen={true}
          onClose={() => setSelectedCase(null)}
          caseStudy={selectedCase}
        />
      )}
    </section>
  );
};

export default CaseStudyPreview;