import React from 'react';
import { TrendingUp, DollarSign, BarChart2, PieChart } from 'lucide-react';

const InvestmentHighlights = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-blue-900 to-indigo-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Investment Highlights</h2>
          <p className="text-xl text-blue-200">Key metrics demonstrating our value proposition</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              title: 'Financial Performance',
              metrics: [
                { label: 'Revenue Growth', value: '35% YoY' },
                { label: 'Gross Margin', value: '68%' },
                { label: 'EBITDA Margin', value: '28%' }
              ]
            },
            {
              title: 'Market Position',
              metrics: [
                { label: 'Market Share', value: '23%' },
                { label: 'Global Presence', value: '40+ Countries' },
                { label: 'Enterprise Clients', value: '50+' }
              ]
            },
            {
              title: 'Growth Potential',
              metrics: [
                { label: 'TAM Growth', value: '22.5% CAGR' },
                { label: 'R&D Investment', value: '$100M+' },
                { label: 'Pipeline Value', value: '$2.5B' }
              ]
            }
          ].map((section, index) => (
            <div key={index} className="bg-white/10 backdrop-blur-md rounded-xl p-8">
              <h3 className="text-xl font-semibold mb-6">{section.title}</h3>
              <div className="space-y-4">
                {section.metrics.map((metric, idx) => (
                  <div key={idx} className="flex justify-between items-center">
                    <span className="text-blue-200">{metric.label}</span>
                    <span className="text-xl font-bold">{metric.value}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InvestmentHighlights;