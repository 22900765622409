import React from 'react';
import { TrendingUp, DollarSign, Users, Timer } from 'lucide-react';

const impactMetrics = [
  {
    category: 'Value Creation',
    stats: [
      { label: 'Client Market Cap Growth', value: '$500B+' },
      { label: 'Cost Savings Delivered', value: '$75B+' },
      { label: 'Revenue Growth Generated', value: '25-40%' }
    ]
  },
  {
    category: 'Digital Transformation',
    stats: [
      { label: 'Digital Initiatives Launched', value: '1000+' },
      { label: 'Efficiency Improvement', value: '35-50%' },
      { label: 'Technology Investment Guided', value: '$100B+' }
    ]
  },
  {
    category: 'Sustainability Impact',
    stats: [
      { label: 'Carbon Reduction Achieved', value: '100M+ tons' },
      { label: 'ESG Programs Implemented', value: '200+' },
      { label: 'Sustainable Investment Advised', value: '$50B+' }
    ]
  }
];

const ClientImpact = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-blue-900 to-indigo-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Transformative Impact
          </h2>
          <p className="text-xl text-blue-200 max-w-3xl mx-auto">
            Delivering measurable results for global industry leaders
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {impactMetrics.map((section) => (
            <div 
              key={section.category}
              className="bg-white/10 backdrop-blur-md rounded-2xl p-8 hover:transform hover:scale-105 transition-all duration-300"
            >
              <h3 className="text-xl font-semibold text-white mb-6">
                {section.category}
              </h3>
              <div className="space-y-6">
                {section.stats.map((stat) => (
                  <div key={stat.label}>
                    <div className="text-3xl font-bold text-blue-300 mb-2">
                      {stat.value}
                    </div>
                    <div className="text-sm text-blue-200">
                      {stat.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 grid md:grid-cols-4 gap-8">
          {[
            { icon: TrendingUp, label: 'Fortune 100 Clients', value: '80%' },
            { icon: DollarSign, label: 'Assets Under Advisory', value: '$2T+' },
            { icon: Users, label: 'Global Experts', value: '5,000+' },
            { icon: Timer, label: 'Years of Excellence', value: '25+' }
          ].map((stat, index) => (
            <div key={index} className="text-center">
              <stat.icon className="h-8 w-8 text-blue-300 mx-auto mb-4" />
              <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
              <div className="text-blue-200">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClientImpact;