import React from 'react';
import { Star } from 'lucide-react';

const testimonials = [
  {
    name: 'Radhakrishnan Gurusamy',
    role: 'CEO, Apexon',
    image: 'https://www.stsworldwide.us/img/Radha.jpg?auto=format&fit=crop&q=80',
    content: 'STS Worldwide transformed our approach to international markets. Their strategic insights were invaluable to our expansion.',
    rating: 5
  },
  {
    name: 'Aho Bilam',
    role: 'President & CEO, MethodHub',
    image: 'https://www.stsworldwide.us/img/aho.jpg?auto=format&fit=crop&q=80',
    content: 'The depth of expertise and personalized attention we received from STS was exceptional. They truly understand global business.',
    rating: 5
  },
  {
    name: 'Raj Sardana',
    role: 'Chief Executive Officer of Innova Solutions',
    image: 'https://www.stsworldwide.us/img/raj.png?auto=format&fit=crop&q=80',
    content: 'Working with STS Worldwide has been transformative for our business. Their strategic guidance helped us navigate complex challenges.',
    rating: 5
  }
];

const Testimonials = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Client Success Stories
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Hear from our clients about their experience working with STS Worldwide
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex gap-1 mb-4">
                {[...Array(testimonial.rating)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                ))}
              </div>
              <p className="text-gray-600 mb-6">"{testimonial.content}"</p>
              <div className="flex items-center">
                <img 
                  src={testimonial.image} 
                  alt={testimonial.name}
                  className="h-12 w-12 rounded-full object-cover mr-4"
                />
                <div>
                  <div className="font-semibold text-gray-900">{testimonial.name}</div>
                  <div className="text-sm text-gray-600">{testimonial.role}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;