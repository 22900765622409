import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.startsWith(path);
  };

  const getLinkClasses = (path: string) => {
    return `transition-colors duration-300 ${
      isActive(path)
        ? 'text-blue-600 font-medium'
        : 'text-gray-800 hover:text-blue-500'
    }`;
  };

  const getMobileLinkClasses = (path: string) => {
    return `block w-full px-4 py-2 transition-colors duration-300 ${
      isActive(path)
        ? 'text-blue-600 font-medium bg-blue-50'
        : 'text-gray-800 hover:text-blue-600 hover:bg-gray-50'
    }`;
  };

  return (
    <nav className={`fixed w-full z-50 bg-white shadow-lg transition-all duration-300`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-24">
          <Link to="/" className="flex items-center py-2">
            <img 
              src="src/img/logo.jpg" 
              alt="STS Worldwide Growth Advisory" 
              className="h-20 w-auto object-contain"
            />
          </Link>
          
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className={getLinkClasses('/')}>Home</Link>
            <Link to="/services" className={getLinkClasses('/services')}>Services</Link>
            <Link to="/about" className={getLinkClasses('/about')}>About</Link>
            <Link to="/insights" className={getLinkClasses('/insights')}>Insights</Link>
            <Link to="/investor" className={getLinkClasses('/investor')}>Investor Relations</Link>
            <Link to="/contact" className={getLinkClasses('/contact')}>Contact</Link>
          </div>

          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden flex items-center"
          >
            {isOpen ? 
              <X className="h-6 w-6 text-gray-900" /> : 
              <Menu className="h-6 w-6 text-gray-900" />
            }
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden absolute w-full bg-white">
          <div className="px-4 pt-2 pb-3 space-y-1">
            <Link to="/" className={getMobileLinkClasses('/')}>Home</Link>
            <Link to="/services" className={getMobileLinkClasses('/services')}>Services</Link>
            <Link to="/about" className={getMobileLinkClasses('/about')}>About</Link>
            <Link to="/insights" className={getMobileLinkClasses('/insights')}>Insights</Link>
            <Link to="/investor" className={getMobileLinkClasses('/investor')}>Investor Relations</Link>
            <Link to="/contact" className={getMobileLinkClasses('/contact')}>Contact</Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;