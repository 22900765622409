import React from 'react';
import Hero from '../components/home/Hero';
import SolutionsSection from '../components/home/solutions/SolutionsSection';
import GlobalPresenceMap from '../components/global/GlobalPresenceMap';
import IndustryExpertise from '../components/expertise/IndustryExpertise';
import CaseStudies from '../components/home/CaseStudies';
import ClientImpact from '../components/impact/ClientImpact';
import Testimonials from '../components/home/Testimonials';
import ResearchInsights from '../components/thought-leadership/ResearchInsights';
import GlobalPartners from '../components/enterprise/GlobalPartners';
import Contact from '../components/home/Contact';

const Home = () => {
  return (
    <>
      <Hero />
      <SolutionsSection />
      <GlobalPresenceMap />
      <IndustryExpertise />
      <Testimonials />
      <GlobalPartners />
      <Contact />
    </>
  );
};

export default Home;