import React from 'react';
import { TrendingUp, DollarSign, BarChart2, PieChart } from 'lucide-react';

const FinancialHighlights = () => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Financial Performance</h2>
          <p className="text-xl text-gray-600">Key metrics demonstrating our strong financial position</p>
        </div>

        <div className="grid md:grid-cols-4 gap-8">
          {[
            { icon: TrendingUp, label: 'Revenue Growth', value: '35%', subtext: 'Year over Year' },
            { icon: DollarSign, label: 'EBITDA Margin', value: '28%', subtext: 'Q3 2023' },
            { icon: BarChart2, label: 'Market Cap', value: '$2.8M', subtext: 'As of Dec 2024' },
            { icon: PieChart, label: 'ROE', value: '22%', subtext: 'TTM' }
          ].map((metric, index) => (
            <div key={index} className="bg-gray-50 p-6 rounded-xl">
              <metric.icon className="h-8 w-8 text-blue-600 mb-4" />
              <div className="text-3xl font-bold text-gray-900 mb-1">{metric.value}</div>
              <div className="text-sm text-gray-600">{metric.label}</div>
              <div className="text-xs text-gray-500 mt-1">{metric.subtext}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FinancialHighlights;