import React from 'react';
import { Shield, Lock, Eye, FileText } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <Shield className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
          <p className="text-xl text-gray-600">
            Your privacy is important to us. Learn how STS Worldwide protects and manages your data.
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <div className="prose prose-blue max-w-none">
            <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4">
              <Lock className="h-6 w-6 mr-2 text-blue-600" />
              Data Protection Commitment
            </h2>
            <p className="text-gray-600 mb-6">
              At STS Worldwide, we take the protection and privacy of your data seriously. This privacy policy outlines how we collect, use, and protect your personal information when you use our services or visit our website.
            </p>

            <h3 className="flex items-center text-xl font-semibold text-gray-900 mb-4">
              <Eye className="h-6 w-6 mr-2 text-blue-600" />
              Information We Collect
            </h3>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li>Contact information (name, email, phone number)</li>
              <li>Business information</li>
              <li>Website usage data</li>
              <li>Communication preferences</li>
            </ul>

            <h3 className="flex items-center text-xl font-semibold text-gray-900 mb-4">
              <FileText className="h-6 w-6 mr-2 text-blue-600" />
              How We Use Your Information
            </h3>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li>Providing and improving our services</li>
              <li>Communicating about our services</li>
              <li>Analyzing and improving website performance</li>
              <li>Complying with legal obligations</li>
            </ul>

            {/* Add more sections as needed */}
          </div>
        </div>

        <div className="text-center text-gray-600">
          <p>Last updated: March 15, 2024</p>
          <p>For questions about our privacy policy, please contact our Data Protection Officer at privacy@stsworldwide.com</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;