import React from 'react';
import { TrendingUp, Download } from 'lucide-react';
import AnimatedBackground from '../ui/AnimatedBackground';

const InvestorHero = () => {
  return (
    <section className="relative min-h-[60vh] flex items-center">
      <AnimatedBackground />
      
      <div className="relative z-20 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center">
          <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
            Investor <br />
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-violet-400">
              Relations
            </span>
          </h1>
          <p className="text-xl text-gray-200 max-w-3xl mx-auto mb-8">
            Delivering sustainable value through strategic growth and operational excellence
          </p>
          
          <div className="flex flex-wrap justify-center gap-4">

          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestorHero;