import React from 'react';
import { Globe2 } from 'lucide-react';
import { OfficeRegion } from './types';

interface RegionSelectorProps {
  regions: OfficeRegion[];
  activeRegion: string;
  onRegionChange: (region: string) => void;
}

const RegionSelector = ({ regions, activeRegion, onRegionChange }: RegionSelectorProps) => {
  return (
    <div className="sticky top-24">
      <h3 className="text-xl font-semibold text-white mb-6">Global Regions</h3>
      <div className="space-y-3">
        {regions.map((region) => (
          <button
            key={region.name}
            onClick={() => onRegionChange(region.name)}
            className={`w-full text-left px-6 py-4 rounded-xl transition-all duration-300 ${
              activeRegion === region.name
                ? 'bg-blue-600/20 border-l-4 border-blue-400'
                : 'bg-white/5 hover:bg-white/10'
            }`}
          >
            <div className="flex items-center">
              <Globe2 className={`h-5 w-5 mr-3 ${
                activeRegion === region.name ? 'text-blue-400' : 'text-blue-300'
              }`} />
              <span className={activeRegion === region.name ? 'text-white' : 'text-blue-200'}>
                {region.name}
              </span>
              <span className="ml-auto text-sm text-blue-300">
                {region.offices.length} {region.offices.length === 1 ? 'Office' : 'Offices'}
              </span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default RegionSelector;