import React from 'react';
import { Industry } from '../types';
import { Building2, Trophy, Users, BarChart } from 'lucide-react';

interface IndustryDetailsProps {
  industry: Industry;
}

const IndustryDetails: React.FC<IndustryDetailsProps> = ({ industry }) => {
  return (
    <div className="bg-white p-8 rounded-2xl shadow-lg">
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <industry.icon className="h-8 w-8 text-blue-600 mr-3" />
          <h3 className="text-2xl font-bold text-gray-900">
            {industry.name}
          </h3>
        </div>
        <p className="text-gray-600 text-lg">
          {industry.description}
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8 mb-8">
        <div>
          <h4 className="text-lg font-semibold text-gray-900 mb-4">Core Expertise</h4>
          <ul className="space-y-3">
            {industry.expertise.map((item) => (
              <li key={item} className="flex items-center text-gray-600">
                <span className="h-2 w-2 bg-blue-600 rounded-full mr-3" />
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h4 className="text-lg font-semibold text-gray-900 mb-4">Client Profile</h4>
          <ul className="space-y-3">
            {industry.clients.map((client) => (
              <li key={client} className="flex items-center text-gray-600">
                <Building2 className="h-4 w-4 text-blue-600 mr-3" />
                {client}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-8">
        <div className="bg-blue-50 p-4 rounded-xl">
          <Trophy className="h-6 w-6 text-blue-600 mb-2" />
          <div className="text-2xl font-bold text-gray-900">{industry.keyMetrics.projects}</div>
          <div className="text-sm text-gray-600">Projects</div>
        </div>
        <div className="bg-blue-50 p-4 rounded-xl">
          <Building2 className="h-6 w-6 text-blue-600 mb-2" />
          <div className="text-2xl font-bold text-gray-900">{industry.keyMetrics.clients}</div>
          <div className="text-sm text-gray-600">Clients</div>
        </div>
        <div className="bg-blue-50 p-4 rounded-xl">
          <Users className="h-6 w-6 text-blue-600 mb-2" />
          <div className="text-2xl font-bold text-gray-900">{industry.keyMetrics.experts}</div>
          <div className="text-sm text-gray-600">Experts</div>
        </div>
        <div className="bg-blue-50 p-4 rounded-xl">
          <BarChart className="h-6 w-6 text-blue-600 mb-2" />
          <div className="text-2xl font-bold text-gray-900">{industry.keyMetrics.satisfaction}</div>
          <div className="text-sm text-gray-600">Satisfaction</div>
        </div>
      </div>

      <div>
        <h4 className="text-lg font-semibold text-gray-900 mb-4">Success Stories</h4>
        <div className="grid md:grid-cols-2 gap-4">
          {industry.successStories.map((story, index) => (
            <div key={index} className="bg-gray-50 p-6 rounded-xl">
              <h5 className="font-semibold text-gray-900 mb-2">{story.title}</h5>
              <div className="text-blue-600 font-medium mb-2">{story.result}</div>
              <p className="text-sm text-gray-600">{story.impact}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndustryDetails;