import React from 'react';
import { Newspaper } from 'lucide-react';
import { TimelineEvent } from './types';
import { getCategoryColor } from './utils';

interface TimelineModalProps {
  event: TimelineEvent;
  onClose: () => void;
}

const TimelineModal: React.FC<TimelineModalProps> = ({ event, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-2xl w-full p-8">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <div className={`p-3 rounded-xl ${getCategoryColor(event.category)} text-white`}>
              <event.icon className="h-6 w-6" />
            </div>
            <div className="ml-4">
              <div className="text-sm text-gray-500">Year</div>
              <div className="text-2xl font-bold text-gray-900">{event.year}</div>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <h3 className="text-2xl font-bold text-gray-900 mb-4">{event.title}</h3>
        <p className="text-gray-600 mb-6">{event.description}</p>
        {event.source && (
          <div className="flex items-center text-gray-500">
            <Newspaper className="h-5 w-5 mr-2" />
            Source: {event.source}
          </div>
        )}
      </div>
    </div>
  );
};

export default TimelineModal;