import React from 'react';
import { Mail } from 'lucide-react';

const NewsletterCTA = () => {
  return (
    <section className="py-24 bg-gradient-to-r from-blue-600 to-violet-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <Mail className="h-12 w-12 text-white/80 mx-auto mb-6" />
          <h2 className="text-3xl font-bold text-white mb-4">
            Stay Ahead of Industry Trends
          </h2>
          <p className="text-lg text-blue-100 mb-8">
            Subscribe to our newsletter for the latest insights and analysis delivered straight to your inbox
          </p>
          
          <form className="flex flex-col sm:flex-row gap-4 max-w-lg mx-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-1 px-6 py-3 rounded-full focus:outline-none focus:ring-2 focus:ring-white/50"
            />
            <button className="px-8 py-3 bg-white text-blue-600 rounded-full font-medium hover:bg-blue-50 transition-colors">
              Subscribe
            </button>
          </form>
          
          <p className="text-sm text-blue-100 mt-4">
            We respect your privacy. Unsubscribe at any time.
          </p>
        </div>
      </div>
    </section>
  );
};

export default NewsletterCTA;