import React from 'react';
import { Leader } from './types';
import { X, Award, GraduationCap, Globe2, BookOpen, Mail, Linkedin, Twitter } from 'lucide-react';

interface LeaderModalProps {
  isOpen: boolean;
  onClose: () => void;
  leader: Leader;
}

const LeaderModal: React.FC<LeaderModalProps> = ({ isOpen, onClose, leader }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 bg-white/90 hover:bg-white rounded-full transition-colors z-10"
          aria-label="Close modal"
        >
          <X className="h-6 w-6 text-gray-500" />
        </button>

        <div className="grid md:grid-cols-2">
          <div className="relative h-96">
            <img 
              src={leader.image}
              alt={leader.name}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent p-8">
              <h3 className="text-2xl font-bold text-white mb-2">
                {leader.name}
              </h3>
              <p className="text-xl text-blue-300">{leader.role}</p>
            </div>
          </div>

          <div className="p-8">
            <div className="space-y-6">
              {leader.bio && (
                <div>
                  <p className="text-gray-600">{leader.bio}</p>
                </div>
              )}

              <div>
                <h4 className="text-lg font-semibold text-gray-900 mb-2">Areas of Expertise</h4>
                <div className="flex flex-wrap gap-2">
                  {leader.expertise.map((skill) => (
                    <span 
                      key={skill}
                      className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>

              {leader.achievements && (
                <div>
                  <h4 className="flex items-center text-lg font-semibold text-gray-900 mb-2">
                    <Award className="h-5 w-5 mr-2 text-blue-600" />
                    Key Achievements
                  </h4>
                  <ul className="space-y-2">
                    {leader.achievements.map((achievement, index) => (
                      <li key={index} className="flex items-start">
                        <span className="w-2 h-2 mt-2 bg-blue-600 rounded-full mr-2" />
                        <span className="text-gray-600">{achievement}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {leader.previousRoles && (
                <div>
                  <h4 className="flex items-center text-lg font-semibold text-gray-900 mb-2">
                    <GraduationCap className="h-5 w-5 mr-2 text-blue-600" />
                    Previous Roles
                  </h4>
                  <ul className="space-y-2">
                    {leader.previousRoles.map((role, index) => (
                      <li key={index} className="flex items-start">
                        <span className="w-2 h-2 mt-2 bg-blue-600 rounded-full mr-2" />
                        <span className="text-gray-600">{role}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <div className="flex space-x-4 pt-4">
                <a
                  href={leader.linkedin}
                  className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
                >
                  <Linkedin className="h-5 w-5 text-gray-600" />
                </a>
                <a
                  href={leader.twitter}
                  className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
                >
                  <Twitter className="h-5 w-5 text-gray-600" />
                </a>
                <a
                  href={`mailto:${leader.email}`}
                  className="p-2 bg-gray-100 rounded-full hover:bg-gray-200 transition-colors"
                >
                  <Mail className="h-5 w-5 text-gray-600" />
                </a>
              </div>

              {/* Close button at bottom */}
              <button
                onClick={onClose}
                className="w-full mt-6 px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderModal;