import { 
  Shield, 
  Target, 
  Users, 
  TrendingUp, 
  Clock, 
  BadgeDollarSign,
  LineChart,
  Globe2,
  Laptop,
  Handshake,
  Building2,
  Search,
  Briefcase
} from 'lucide-react';
import { Service } from '../types';

export const services: Service[] = [
  {
    id: 'professional-services',
    icon: Briefcase,
    title: 'Professional Services',
    description: 'Comprehensive staffing and talent solutions for technology and professional roles across industries.',
    features: [
      {
        title: 'IT Staffing',
        description: 'Specialized technology talent placement',
        icon: Laptop
      },
      {
        title: 'Executive Search',
        description: 'Senior leadership recruitment',
        icon: Users
      },
      {
        title: 'Project Resourcing',
        description: 'Skilled project team deployment',
        icon: Target
      }
    ],
    metrics: [
      {
        value: '10K+',
        label: 'Placements',
        icon: Users
      },
      {
        value: '95%',
        label: 'Retention Rate',
        icon: Target  
      },
      {
        value: '500+',
        label: 'Active Clients',
        icon: Building2
      },
      {
        value: '48hrs',
        label: 'Avg Response',
        icon: Clock
      }
    ],
    benefits: ['Expert Talent', 'Fast Deployment', 'Quality Assurance'],
    cases: [
      {
        title: 'Enterprise IT Transformation',
        description: 'Large-scale IT team deployment for digital transformation',
        results: [
          { metric: 'Team Size', value: '200+ Resources' },
          { metric: 'Time Saved', value: '60% Faster' }
        ],
        industry: 'Banking'
      },
      {
        title: 'Healthcare Tech Initiative',
        description: 'Specialized healthcare IT staffing solution',
        results: [
          { metric: 'Positions Filled', value: '150+' },
          { metric: 'Retention', value: '95%' }
        ],
        industry: 'Healthcare'
      }
    ],
    color: 'from-purple-500 to-purple-600'
  },
  {
    id: 'merger-acquisition',
    icon: Building2,
    title: 'Merger & Acquisition',
    description: 'End-to-end M&A advisory services from strategy development through post-merger integration.',
    features: [
      {
        title: 'Transaction Advisory',
        description: 'Strategic M&A planning and execution',
        icon: Target
      },
      {
        title: 'Deal Valuation',
        description: 'Comprehensive valuation and financial modeling',
        icon: TrendingUp
      },
      {
        title: 'Post-Merger Integration',
        description: 'Seamless integration and synergy realization',
        icon: Users
      }
    ],
    metrics: [
      {
        value: '$10B+',
        label: 'Deal Value',
        icon: TrendingUp
      },
      {
        value: '200+',
        label: 'Transactions',
        icon: Target  
      },
      {
        value: '50+',
        label: 'M&A Experts',
        icon: Users
      },
      {
        value: '95%',
        label: 'Success Rate',
        icon: Clock
      }
    ],
    benefits: ['Strategic Growth', 'Value Creation', 'Market Leadership'],
    cases: [
      {
        title: 'Technology Sector Merger',
        description: 'Strategic merger of two industry leaders',
        results: [
          { metric: 'Deal Size', value: '$2.5B' },
          { metric: 'Synergies', value: '$400M' }
        ],
        industry: 'Technology'
      },
      {
        title: 'Healthcare Acquisition',
        description: 'Cross-border healthcare platform acquisition',
        results: [
          { metric: 'Value Creation', value: '3x ROI' },
          { metric: 'Market Share', value: '+45%' }
        ],
        industry: 'Healthcare'
      }
    ],
    color: 'from-blue-500 to-blue-600'
  },
  {
    id: 'global',
    icon: Globe2,
    title: 'Global Expansion',
    description: 'Navigate new markets with confidence through expert guidance and local insights.',
    features: [
      {
        title: 'Market Entry',
        description: 'Strategic market analysis and entry planning',
        icon: Target
      },
      {
        title: 'Partnership Development',
        description: 'Local partnership identification and setup',
        icon: Users
      },
      {
        title: 'Growth Strategy',
        description: 'International expansion planning',
        icon: TrendingUp
      }
    ],
    metrics: [
      {
        value: '200%',
        label: 'Growth Rate',
        icon: TrendingUp
      },
      {
        value: '15+',
        label: 'Markets',
        icon: Globe2
      },
      {
        value: '30+',
        label: 'Partners',
        icon: Users
      },
      {
        value: '98%',
        label: 'Success Rate',
        icon: Clock
      }
    ],
    benefits: ['Market Access', 'Growth', 'Risk Management'],
    cases: [
      {
        title: 'APAC Expansion',
        description: 'End-to-end market entry strategy',
        results: [
          { metric: 'Revenue Growth', value: '200%' },
          { metric: 'Market Share', value: '15%' }
        ],
        industry: 'Technology'
      },
      {
        title: 'European Market Entry',
        description: 'Multi-country expansion program',
        results: [
          { metric: 'New Markets', value: '5' },
          { metric: 'Growth', value: '150%' }
        ],
        industry: 'Retail'
      }
    ],
    color: 'from-violet-500 to-violet-600'
  },
  {
    id: 'buyer-seller-representation',
    icon: Handshake,
    title: 'Buyer & Seller Representation',
    description: 'Expert M&A advisory services for both buy-side and sell-side transactions, ensuring optimal deal outcomes.',
    features: [
      {
        title: 'Deal Structuring',
        description: 'Strategic transaction structuring and negotiation',
        icon: Target
      },
      {
        title: 'Due Diligence',
        description: 'Comprehensive financial and operational assessment',
        icon: Shield
      },
      {
        title: 'Value Optimization',
        description: 'Maximizing transaction value and terms',
        icon: TrendingUp
      }
    ],
    metrics: [
      {
        value: '$5B+',
        label: 'Deal Value',
        icon: TrendingUp
      },
      {
        value: '100+',
        label: 'Transactions',
        icon: Target
      },
      {
        value: '40+',
        label: 'Experts',
        icon: Users
      },
      {
        value: '95%',
        label: 'Success Rate',
        icon: Clock
      }
    ],
    benefits: ['Deal Optimization', 'Risk Mitigation', 'Value Creation'],
    cases: [
      {
        title: 'Cross-Border Technology Acquisition',
        description: 'Buy-side representation for strategic acquisition',
        results: [
          { metric: 'Deal Value', value: '$2.5B' },
          { metric: 'Synergies', value: '$300M' }
        ],
        industry: 'Technology'
      },
      {
        title: 'Healthcare Group Divestiture',
        description: 'Sell-side advisory for healthcare portfolio',
        results: [
          { metric: 'Multiple', value: '12x EBITDA' },
          { metric: 'Premium', value: '+35%' }
        ],
        industry: 'Healthcare'
      }
    ],
    color: 'from-emerald-500 to-emerald-600'
  },
  {
    id: 'digital',
    icon: Laptop,
    title: 'Digital Transformation',
    description: 'Embrace the future with cutting-edge digital solutions and technology integration.',
    features: [
      {
        title: 'Technology Strategy',
        description: 'Digital roadmap and architecture planning',
        icon: Target
      },
      {
        title: 'Process Automation',
        description: 'Intelligent automation solutions',
        icon: TrendingUp
      },
      {
        title: 'Digital Innovation',
        description: 'Emerging technology adoption',
        icon: Laptop
      }
    ],
    metrics: [
      {
        value: '60%',
        label: 'Automation',
        icon: TrendingUp
      },
      {
        value: '40%',
        label: 'Cost Reduction',
        icon: Target
      },
      {
        value: '25+',
        label: 'Solutions',
        icon: Laptop
      },
      {
        value: '97%',
        label: 'Satisfaction',
        icon: Clock
      }
    ],
    benefits: ['Innovation', 'Efficiency', 'Agility'],
    cases: [
      {
        title: 'Enterprise Digital Transformation',
        description: 'Complete digital overhaul program',
        results: [
          { metric: 'Efficiency', value: '+60%' },
          { metric: 'Cost Savings', value: '$10M' }
        ],
        industry: 'Financial Services'
      },
      {
        title: 'Cloud Migration',
        description: 'Enterprise-wide cloud adoption',
        results: [
          { metric: 'Performance', value: '+45%' },
          { metric: 'TCO Reduction', value: '35%' }
        ],
        industry: 'Healthcare'
      }
    ],
    color: 'from-indigo-500 to-indigo-600'
  },
  {
    id: 'financial',
    icon: BadgeDollarSign,
    title: 'Financial Advisory',
    description: 'Strategic financial guidance to optimize performance and drive sustainable growth.',
    features: [
      {
        title: 'Financial Strategy',
        description: 'Strategic financial planning and optimization',
        icon: Target
      },
      {
        title: 'M&A Advisory',
        description: 'End-to-end merger and acquisition support',
        icon: TrendingUp
      },
      {
        title: 'Capital Structure',
        description: 'Optimal capital structure and funding strategies',
        icon: BadgeDollarSign
      }
    ],
    metrics: [
      {
        value: '35%',
        label: 'ROI Increase',
        icon: TrendingUp
      },
      {
        value: '$2B+',
        label: 'Transactions',
        icon: Target
      },
      {
        value: '20+',
        label: 'Advisors',
        icon: Users
      },
      {
        value: '95%',
        label: 'Success Rate',
        icon: Clock
      }
    ],
    benefits: ['Value Creation', 'Growth', 'Optimization'],
    cases: [
      {
        title: 'Financial Restructuring',
        description: 'Strategic financial restructuring for manufacturing client',
        results: [
          { metric: 'Cost Savings', value: '$50M' },
          { metric: 'EBITDA Growth', value: '+35%' }
        ],
        industry: 'Manufacturing'
      },
      {
        title: 'M&A Transaction',
        description: 'Led successful cross-border acquisition',
        results: [
          { metric: 'Deal Value', value: '$500M' },
          { metric: 'Synergies', value: '$75M' }
        ],
        industry: 'Technology'
      }
    ],
    color: 'from-amber-500 to-amber-600'
  }
];