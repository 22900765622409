import { Partner } from '../types';
import { Globe2, Building2, Briefcase, Handshake, Target, Shield } from 'lucide-react';

export const partners: Partner[] = [
  {
    id: 'global-ma-alliance',
    name: 'Global M&A Alliance',
    type: 'Strategic',
    description: 'Premier network of investment banks and M&A advisory firms specializing in complex cross-border transactions',
    partners: ['JP Morgan Chase', 'Advent Financials', 'Porter Capital', 'Wilco Capital'],
    impact: 'Facilitated $250B+ in cross-border transactions',
    projects: '300+ global M&A deals completed',
    image: 'https://www.stsworldwide.us/img/photo-1486406146926-c627a92ad1ab.avif?auto=format&fit=crop&q=80',
    metrics: [
      { value: '$250B+', label: 'Deal Value' },
      { value: '40+', label: 'Countries' },
      { value: '300+', label: 'M&A Deals' }
    ],
    capabilities: [
      'Large-scale M&A Execution',
      'Cross-border Integration',
      'Strategic Due Diligence',
      'Deal Structuring',
      'Regulatory Compliance',
      'Post-merger Value Creation'
    ],
    regions: ['Americas', 'EMEA', 'Asia Pacific', 'Emerging Markets'],
    industries: ['Technology', 'Financial Services', 'Healthcare', 'Industrial']
  },
  {
    id: 'ma-tech-consortium',
    name: 'M&A Technology Consortium',
    type: 'Technology',
    description: 'Specialized alliance focused on technology-enabled M&A and digital transformation deals',
    partners: ['IT Serve', 'CrossCountry Consulting', 'JP Morgan Chase', 'PNC Bank'],
    impact: 'Enabled $100B+ in tech-driven M&A',
    projects: '200+ digital transformation deals',
    image: 'https://www.stsworldwide.us/img/photo-1451187580459-43490279c0fa.avif?auto=format&fit=crop&q=80',
    metrics: [
      { value: '$100B+', label: 'Tech M&A Value' },
      { value: '200+', label: 'Digital Deals' },
      { value: '45%', label: 'Success Rate' }
    ],
    capabilities: [
      'Technology Due Diligence',
      'Digital Integration',
      'IT Infrastructure Merger',
      'Data Migration Strategy',
      'Tech Stack Consolidation',
      'Digital Synergy Realization'
    ],
    regions: ['Silicon Valley', 'Tech Hubs', 'Global Centers'],
    industries: ['Software', 'Cloud Services', 'Digital Platforms', 'Tech Infrastructure']
  },
  {
    id: 'private-equity-network',
    name: 'Private Equity M&A Network',
    type: 'Investment',
    description: 'Elite consortium of private equity firms specializing in buyouts and strategic acquisitions',
    partners: ['JP Morgan Chase', 'Sun Trust ', 'Axis Bank', 'Synovus Bank', 'ICICI Bank'],
    impact: 'Executed $150B+ in PE-backed M&A',
    projects: '250+ portfolio acquisitions',
    image: 'https://www.stsworldwide.us/img/photo-1507679799987-c73779587ccf.avif?auto=format&fit=crop&q=80',
    metrics: [
      { value: '$150B+', label: 'Transaction Value' },
      { value: '250+', label: 'Acquisitions' },
      { value: '3.5x', label: 'Avg. Multiple' }
    ],
    capabilities: [
      'Buy-and-Build Strategy',
      'Portfolio Company M&A',
      'Value Creation Planning',
      'Exit Strategy Optimization',
      'Operational Improvement',
      'Industry Consolidation'
    ],
    regions: ['North America', 'Europe', 'Asia', 'Growth Markets'],
    industries: ['Consumer', 'Healthcare', 'Technology', 'Industrial']
  },
  {
    id: 'cross-border-alliance',
    name: 'Cross-Border M&A Alliance',
    type: 'Strategic',
    description: 'Specialized network focusing on complex international mergers and acquisitions',
    partners: ['Leading Regional Banks', 'M&A Advisory Firms', 'Legal Networks', 'Big Four Firms'],
    impact: 'Completed $80B+ in cross-border deals',
    projects: '150+ international transactions',
    image: 'https://www.stsworldwide.us/img/photo-1526304640581-d334cdbbf45e.avif?auto=format&fit=crop&q=80',
    metrics: [
      { value: '$80B+', label: 'Deal Value' },
      { value: '150+', label: 'Transactions' },
      { value: '35+', label: 'Countries' }
    ],
    capabilities: [
      'International Deal Structure',
      'Cross-border Due Diligence',
      'Cultural Integration',
      'Global Regulatory Compliance',
      'Tax-Efficient Structuring',
      'International PMI'
    ],
    regions: ['EMEA', 'APAC', 'Americas', 'Emerging Markets'],
    industries: ['Manufacturing', 'Energy', 'Infrastructure', 'Consumer Goods']
  },
  {
    id: 'emerging-markets-ma',
    name: 'Emerging Markets M&A Network',
    type: 'Strategic',
    description: 'Specialized alliance for M&A opportunities in high-growth emerging markets',
    partners: ['Regional Leaders', 'Development Banks', 'Investment Firms', 'Advisory Networks'],
    impact: 'Facilitated $50B+ in emerging market M&A',
    projects: '180+ strategic transactions',
    image: 'https://www.stsworldwide.us/img/photo-1512453979798-5ea266f8880c.avif?auto=format&fit=crop&q=80',
    metrics: [
      { value: '$50B+', label: 'Deal Value' },
      { value: '180+', label: 'Transactions' },
      { value: '20+', label: 'Markets' }
    ],
    capabilities: [
      'Market Entry Strategy',
      'Local Partner Selection',
      'Risk Assessment',
      'Government Relations',
      'Cultural Due Diligence',
      'Emerging Market PMI'
    ],
    regions: ['BRICS', 'ASEAN', 'MENA', 'Africa'],
    industries: ['Digital Economy', 'Infrastructure', 'Natural Resources', 'Financial Services']
  }
];