import React from 'react';
import { X, Calendar, User, Share2, Twitter, Linkedin, Facebook, BookOpen, Clock, Tag, Download, ArrowRight } from 'lucide-react';

interface ArticleModalProps {
  isOpen: boolean;
  onClose: () => void;
  article?: {
    title: string;
    category: string;
    author: string;
    date: string;
    image: string;
    excerpt: string;
  };
}

const ArticleModal: React.FC<ArticleModalProps> = ({ isOpen, onClose, article }) => {
  if (!isOpen || !article) return null;

  const getArticleContent = () => {
    if (article.category === 'M&A Insights') {
      return {
        executiveSummary: 'This comprehensive analysis explores the evolving landscape of cross-border M&A in 2024, focusing on regulatory challenges, cultural integration, and strategies for successful international deals.',
        content: [
          {
            title: 'Current M&A Landscape',
            text: 'The global M&A market continues to evolve rapidly in 2024, with cross-border transactions becoming increasingly complex due to geopolitical tensions, regulatory scrutiny, and technological disruption. Understanding these dynamics is crucial for successful deal execution.'
          },
          {
            title: 'Key Challenges in Cross-Border M&A',
            text: 'Cross-border M&A transactions face unique challenges including regulatory compliance across multiple jurisdictions, cultural integration issues, and complex deal structures. Successful navigation requires deep expertise and careful planning.'
          },
          {
            title: 'Strategic Considerations',
            text: 'Successful cross-border M&A requires a comprehensive approach that considers regulatory requirements, cultural factors, and operational integration challenges. Key focus areas include:'
          }
        ],
        keyPoints: [
          'Regulatory Compliance Framework',
          'Cultural Due Diligence',
          'Integration Planning',
          'Risk Management',
          'Synergy Realization',
          'Stakeholder Management'
        ],
        caseStudy: {
          title: 'Global Technology Acquisition',
          metrics: {
            value: '$2.5B',
            timeline: '9 months',
            synergies: '$300M'
          }
        }
      };
    }
    return {
      executiveSummary: 'This article explores the evolving landscape of digital transformation in 2024, focusing on the integration of AI, machine learning, and emerging technologies.',
      content: [
        {
          title: 'The Digital Revolution',
          text: 'As we move further into 2024, artificial intelligence continues to reshape how businesses operate and make decisions. This transformation is not just about implementing new technologies - it is about fundamentally rethinking business processes and strategies.'
        },
        {
          title: 'AI-Driven Innovation',
          text: 'Artificial intelligence is driving unprecedented changes in how businesses operate, make decisions, and create value. Organizations must adapt to remain competitive in this rapidly evolving landscape.'
        },
        {
          title: 'Implementation Strategies',
          text: 'Successful digital transformation requires a comprehensive approach that considers technology, people, and processes. Key considerations include:'
        }
      ],
      keyPoints: [
        'Technology Assessment',
        'Change Management',
        'Data Strategy',
        'Security Integration',
        'Process Optimization',
        'Talent Development'
      ],
      caseStudy: {
        title: 'Enterprise Digital Transformation',
        metrics: {
          efficiency: '45%',
          savings: '$2.5M',
          timeline: '6 months'
        }
      }
    };
  };

  const articleContent = getArticleContent();

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 bg-white/90 hover:bg-white rounded-full transition-colors z-10"
          aria-label="Close modal"
        >
          <X className="h-6 w-6 text-gray-500" />
        </button>

        {/* Article Header Image */}
        <div className="relative h-80">
          <img
            src={article.image}
            alt={article.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <div className="absolute bottom-6 left-6 right-6">
            <div className="flex items-center space-x-4 text-white/80 mb-4">
              <span className="px-3 py-1 bg-blue-600 rounded-full text-sm">{article.category}</span>
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-2" />
                8 min read
              </div>
            </div>
          </div>
        </div>

        <div className="p-8">
          {/* Article Meta */}
          <div className="flex flex-wrap items-center justify-between mb-6">
            <div className="flex items-center space-x-6 text-sm text-gray-500">
              <div className="flex items-center">
                <User className="h-4 w-4 mr-2" />
                {article.author}
              </div>
              <div className="flex items-center">
                <Calendar className="h-4 w-4 mr-2" />
                {article.date}
              </div>
              <div className="flex items-center">
                <BookOpen className="h-4 w-4 mr-2" />
                {article.category}
              </div>
            </div>
          </div>

          {/* Article Title */}
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            {article.title}
          </h1>

          {/* Executive Summary */}
          <div className="bg-gray-50 p-6 rounded-xl mb-8">
            <h2 className="text-lg font-semibold text-gray-900 mb-3">Executive Summary</h2>
            <p className="text-gray-600">
              {articleContent.executiveSummary}
            </p>
          </div>

          {/* Article Content */}
          <div className="prose max-w-none mb-8">
            {articleContent.content.map((section, index) => (
              <div key={index} className="mb-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">{section.title}</h2>
                <p className="text-gray-600 text-lg leading-relaxed">{section.text}</p>
                
                {index === 2 && (
                  <ul className="space-y-4 mt-4">
                    {articleContent.keyPoints.map((point, idx) => (
                      <li key={idx} className="flex items-start">
                        <span className="h-2 w-2 bg-blue-600 rounded-full mt-2 mr-3" />
                        <span className="text-gray-600">{point}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            {/* Case Study Section */}
            <div className="bg-blue-50 p-6 rounded-xl mb-8">
              <h3 className="text-xl font-bold text-gray-900 mb-4">Case Study: {articleContent.caseStudy.title}</h3>
              <div className="grid grid-cols-3 gap-4">
                {Object.entries(articleContent.caseStudy.metrics).map(([key, value]) => (
                  <div key={key} className="bg-white p-4 rounded-lg">
                    <div className="text-blue-600 font-bold">{value}</div>
                    <div className="text-sm text-gray-600 capitalize">{key}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Tags */}
          <div className="flex items-center space-x-2 mb-8">
            <Tag className="h-4 w-4 text-gray-400" />
            <div className="flex flex-wrap gap-2">
              {['M&A', 'Strategy', 'Global Business', 'Cross-border'].map((tag) => (
                <span key={tag} className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm">
                  {tag}
                </span>
              ))}
            </div>
          </div>

          {/* Share Section */}
          <div className="border-t border-gray-200 pt-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Share2 className="h-5 w-5 text-gray-400 mr-3" />
                <span className="text-gray-600">Share this article:</span>
              </div>
              <div className="flex space-x-4">
                <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
                  <Twitter className="h-5 w-5 text-gray-600" />
                </button>
                <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
                  <Linkedin className="h-5 w-5 text-gray-600" />
                </button>
                <button className="p-2 hover:bg-gray-100 rounded-full transition-colors">
                  <Facebook className="h-5 w-5 text-gray-600" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleModal;