import React from 'react';
import { motion } from 'framer-motion';
import { Award, Globe2, Users, TrendingUp } from 'lucide-react';

const stats = [
  {
    icon: Users,
    value: '40+',
    label: 'Years of Combined Experience',
    color: 'bg-blue-100 text-blue-600'
  },
  {
    icon: Globe2,
    value: '10+',
    label: 'Countries Expanded To',
    color: 'bg-green-100 text-green-600'
  },
  {
    icon: Award,
    value: '50+',
    label: 'Industry Awards',
    color: 'bg-purple-100 text-purple-600'
  },
  {
    icon: TrendingUp,
    value: '500%',
    label: 'Company Growth',
    color: 'bg-amber-100 text-amber-600'
  }
];

const FounderStats = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: index * 0.1 }}
          className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <div className={`inline-flex p-3 rounded-xl ${stat.color} mb-4`}>
            <stat.icon className="h-6 w-6" />
          </div>
          <div className="text-3xl font-bold text-gray-900 mb-2">{stat.value}</div>
          <div className="text-sm text-gray-600">{stat.label}</div>
        </motion.div>
      ))}
    </div>
  );
};

export default FounderStats;