import { TimelineEvent } from '../types';
import { Award, Building2, Globe2, TrendingUp, Trophy, Star, Newspaper } from 'lucide-react';

export const timelineEvents: TimelineEvent[] = [
  {
    id: 'foundation-1983',
    year: 1983,
    title: 'Company Foundation',
    description: 'Started by Mrs. Geetha Narasimham, marking the beginning of a global success story.',
    category: 'milestone',
    icon: Building2
  },
  {
    id: 'inc-magazine-1992',
    year: 1992,
    title: 'Inc. Magazine Recognition',
    description: 'Ranked 193rd fastest growing privately held company in USA - Inc. Magazine\'s top 500.',
    category: 'recognition',
    icon: Trophy,
    source: 'Inc. Magazine'
  },
  {
    id: 'india-ops-1993',
    year: 1993,
    title: 'Indian Operations',
    description: 'Expanded operations to India, establishing a strong presence in the subcontinent.',
    category: 'milestone',
    icon: Globe2
  },
  {
    id: 'blue-chip-1994',
    year: 1994,
    title: 'BLUE CHIP Award',
    description: 'Recognized as one of the best managed companies in Georgia by Nation\'s Business magazine.',
    category: 'award',
    icon: Award,
    source: 'Nation\'s Business Magazine'
  },
  {
    id: 'top-employer-1995',
    year: 1995,
    title: 'Top Employer Recognition',
    description: '27th fastest employer in Atlanta, Georgia - HOOVER\'S master list.',
    category: 'recognition',
    icon: Star
  },
  {
    id: 'india-abroad-1996',
    year: 1996,
    title: 'India Abroad Recognition',
    description: 'Ranked 16th in India Abroad\'s top 100 Indian-owned companies in USA.',
    category: 'recognition',
    icon: Trophy,
    source: 'India Abroad'
  },
  {
    id: 'women-ceo-1996',
    year: 1996,
    title: 'Women Leadership Recognition',
    description: 'Mrs. Geetha recognized as one of only two women CEOs of Indian origin in USA.',
    category: 'recognition',
    icon: Star,
    source: 'Economic Times of India'
  },
  {
    id: 'apac-expansion-1996',
    year: 1996,
    title: 'APAC Expansion',
    description: 'Extended operations to Asia Pacific region, strengthening global presence.',
    category: 'milestone',
    icon: Globe2
  }
];