import React from 'react';
import { Shield, Users, Leaf, Scale } from 'lucide-react';

const GovernanceOverview = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 to-blue-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Corporate Governance</h2>
          <p className="text-xl text-blue-200">Committed to transparency and ethical business practices</p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-white/10 backdrop-blur-md rounded-xl p-8">
            <h3 className="text-xl font-semibold mb-6">Board Composition</h3>
            <div className="space-y-4">
              <div className="flex items-center">
                <Users className="h-5 w-5 text-blue-400 mr-3" />
                <span>8 Independent Directors (80%)</span>
              </div>
              <div className="flex items-center">
                <Users className="h-5 w-5 text-blue-400 mr-3" />
                <span>40% Gender Diversity</span>
              </div>
              <div className="flex items-center">
                <Users className="h-5 w-5 text-blue-400 mr-3" />
                <span>4 Specialized Committees</span>
              </div>
            </div>
          </div>

          <div className="bg-white/10 backdrop-blur-md rounded-xl p-8">
            <h3 className="text-xl font-semibold mb-6">ESG Initiatives</h3>
            <div className="space-y-4">
              <div className="flex items-center">
                <Leaf className="h-5 w-5 text-green-400 mr-3" />
                <span>Net Zero by 2030</span>
              </div>
              <div className="flex items-center">
                <Scale className="h-5 w-5 text-green-400 mr-3" />
                <span>ESG Risk Rating: AA</span>
              </div>
              <div className="flex items-center">
                <Shield className="h-5 w-5 text-green-400 mr-3" />
                <span>Top 10% in Industry ESG Score</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GovernanceOverview;