import React from 'react';
import SolutionsHeader from './SolutionsHeader';
import SolutionsList from './SolutionsList';
import SolutionsStats from './SolutionsStats';

const SolutionsSection = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SolutionsHeader />
        <SolutionsStats />
        <SolutionsList />
      </div>
    </section>
  );
};

export default SolutionsSection;