import React from 'react';
import { Globe2, Users, Trophy, Target } from 'lucide-react';

const features = [
  {
    icon: Globe2,
    title: 'Global Reach',
    description: 'Operating in over 50 countries with deep local market expertise.'
  },
  {
    icon: Users,
    title: 'Expert Team',
    description: 'Industry veterans and subject matter experts dedicated to your success.'
  },
  {
    icon: Trophy,
    title: 'Proven Track Record',
    description: '20+ years of delivering exceptional results for our clients.'
  },
  {
    icon: Target,
    title: 'Strategic Focus',
    description: 'Tailored solutions aligned with your business objectives.'
  }
];

const WhoWeAre = () => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <div>
            <h2 className="text-4xl font-bold text-gray-900 mb-6">Who We Are</h2>
            <p className="text-lg text-gray-600 mb-8">
              STS Worldwide isn't just a business advisory firm — we are strategic partners to organizations that seek meaningful growth, innovation, and success. With a global footprint and a passion for creating tailored solutions, we help businesses navigate complexity, unlock opportunities, and achieve lasting results.
            </p>
            <p className="text-lg text-gray-600">
              Our diverse team of industry leaders, strategists, and innovators blends experience with creativity, delivering strategies that don't just adapt to change but shape the future.
            </p>
          </div>
          <div className="grid grid-cols-2 gap-6">
            {features.map((feature, index) => (
              <div key={index} className="p-6 rounded-xl bg-gray-50 hover:bg-blue-50 transition-colors duration-300">
                <feature.icon className="h-8 w-8 text-blue-600 mb-4" />
                <h3 className="font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-sm text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;