import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const faqs = [
  {
    question: 'How quickly can I expect a response to my inquiry?',
    answer: 'We strive to respond to all inquiries within 24 business hours. For urgent matters, please contact us directly by phone.'
  },
  {
    question: 'Do you offer virtual consultations?',
    answer: 'Yes, we offer virtual consultations via video conference for clients worldwide. We use secure platforms to ensure confidentiality.'
  },
  {
    question: 'What industries do you specialize in?',
    answer: 'We work across various industries including technology, finance, healthcare, manufacturing, and retail, with specialized teams for each sector.'
  },
  {
    question: 'How do you ensure client confidentiality?',
    answer: 'We maintain strict confidentiality protocols and sign NDAs with all clients. Our security measures exceed industry standards.'
  }
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-xl text-gray-600">
            Find quick answers to common questions
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-xl overflow-hidden shadow-sm"
            >
              <button
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                className="w-full flex items-center justify-between p-6 text-left"
              >
                <span className="font-semibold text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <Minus className="h-5 w-5 text-blue-600" />
                ) : (
                  <Plus className="h-5 w-5 text-gray-400" />
                )}
              </button>
              
              <div className={`px-6 overflow-hidden transition-all duration-300 ${
                openIndex === index ? 'pb-6 max-h-40' : 'max-h-0'
              }`}>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;