import React from 'react';
import { FileText, Download, Calendar, PieChart } from 'lucide-react';

const InvestorResources = () => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Investor Resources</h2>
          <p className="text-xl text-gray-600">Access key documents and information</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: FileText,
              title: 'Financial Reports',
              items: ['Annual Report 2023', 'Q4 2023 Results', 'SEC Filings']
            },
            {
              icon: PieChart,
              title: 'Presentations',
              items: ['Investor Day 2024', 'Strategy Update', 'Growth Outlook']
            },
            {
              icon: Calendar,
              title: 'Events',
              items: ['Q1 2024 Earnings Call', 'Annual Meeting', 'Investor Day']
            },
            {
              icon: Download,
              title: 'Downloads',
              items: ['Investor Factsheet', 'ESG Report', 'Corporate Governance']
            }
          ].map((resource, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-6">
              <resource.icon className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{resource.title}</h3>
              <ul className="space-y-3">
                {resource.items.map((item, idx) => (
                  <li key={idx}>
                    <button className="flex items-center text-gray-600 hover:text-blue-600">
                      <Download className="h-4 w-4 mr-2" />
                      {item}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InvestorResources;