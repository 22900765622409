import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Solution } from './types';

interface SolutionCardProps {
  solution: Solution;
  isActive: boolean;
  onClick: () => void;
}

const SolutionCard: React.FC<SolutionCardProps> = ({ solution, isActive, onClick }) => {
  return (
    <motion.div
      onClick={onClick}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ y: -5 }}
      className={`group relative overflow-hidden rounded-2xl transition-all duration-500 ease-out
        ${isActive ? 'bg-white' : 'bg-gray-50'} 
        hover:shadow-xl cursor-pointer border border-gray-100`}
    >
      {/* Card Content */}
      <div className="p-8">
        {/* Icon */}
        <div className="mb-6">
          <motion.div 
            whileHover={{ scale: 1.1 }}
            className={`inline-flex p-4 rounded-xl bg-gradient-to-br ${solution.color}`}
          >
            <solution.icon className="h-6 w-6 text-white" />
          </motion.div>
        </div>

        {/* Title & Description */}
        <h3 className="text-xl font-semibold text-gray-900 mb-3 group-hover:text-blue-600 transition-colors">
          {solution.title}
        </h3>
        <p className="text-gray-600 mb-6">
          {solution.description}
        </p>

        {/* Features List */}
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="space-y-4 mb-6"
          >
            {solution.features.map((feature, index) => (
              <motion.div
                key={feature}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center text-gray-600"
              >
                <span className="h-1.5 w-1.5 rounded-full bg-blue-600 mr-3" />
                {feature}
              </motion.div>
            ))}
          </motion.div>
        )}

        {/* Benefits & Learn More */}
        <div className="flex items-center justify-between mt-auto">
          <div className="flex gap-2">
            {solution.benefits.slice(0, 2).map((benefit) => (
              <span
                key={benefit}
                className={`px-3 py-1 text-sm rounded-full 
                  ${isActive ? 'bg-blue-50 text-blue-600' : 'bg-gray-100 text-gray-600'}`}
              >
                {benefit}
              </span>
            ))}
          </div>

          <motion.button
            whileHover={{ x: 5 }}
            className="flex items-center text-blue-600 font-medium"
          >
            Learn More
            <ArrowRight className="ml-2 h-4 w-4" />
          </motion.button>
        </div>
      </div>

      {/* Bottom Gradient Line */}
      <motion.div 
        className={`absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r ${solution.color}`}
        initial={{ scaleX: 0 }}
        animate={{ scaleX: isActive ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      />
    </motion.div>
  );
};

export default SolutionCard;