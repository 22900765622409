import { 
  LineChart, 
  Globe2, 
  Building2, 
  Handshake, 
  BadgeDollarSign,
  Search
} from 'lucide-react';
import { Solution } from '../types';

// Define categories as a constant
const SOLUTION_CATEGORIES = ['all', 'strategy', 'global', 'ma', 'financial', 'solutions-selling'] as const;

// Export categories for use in components
export const categories = SOLUTION_CATEGORIES;

// Define and export solutions array
export const solutions: Solution[] = [
  {
    id: 'strategy',
    icon: LineChart,
    title: 'Strategy and Operations',
    description: 'Optimize your business operations and develop winning strategies for sustainable growth.',
    features: [
      'Strategic Planning & Roadmap Development',
      'Performance Optimization & KPI Management',
      'Business Process Reengineering',
      'Supply Chain Optimization'
    ],
    benefits: ['Growth', 'Efficiency', 'Innovation'],
    color: 'from-blue-500 to-blue-600',
    category: 'strategy'
  },
  {
    id: 'global',
    icon: Globe2,
    title: 'Global Expansion',
    description: 'Navigate new markets with confidence through expert guidance and local insights.',
    features: [
      'Market Entry Strategy Development',
      'International Market Analysis',
      'Local Partnership Development',
      'Cross-border M&A Advisory'
    ],
    benefits: ['Market Access', 'Growth', 'Risk Management'],
    color: 'from-violet-500 to-violet-600',
    category: 'global'
  },
  {
    id: 'ma-advisory',
    icon: Search,
    title: 'M&A Advisory Services',
    description: 'Comprehensive M&A advisory services from target identification through deal closure and integration.',
    features: [
      'Target Screening & Identification',
      'Strategic Fit Analysis',
      'Deal Structuring & Negotiation',
      'Transaction Advisory'
    ],
    benefits: ['Deal Success', 'Strategic Fit', 'Value Maximization'],
    color: 'from-indigo-500 to-indigo-600',
    category: 'ma'
  },
  {
    id: 'ma-execution',
    icon: Building2,
    title: 'M&A Deal Execution',
    description: 'End-to-end M&A execution services ensuring successful transactions and value creation.',
    features: [
      'Due Diligence Management',
      'Valuation & Financial Modeling',
      'Deal Documentation',
      'Regulatory Compliance'
    ],
    benefits: ['Transaction Speed', 'Risk Mitigation', 'Deal Optimization'],
    color: 'from-emerald-500 to-emerald-600',
    category: 'ma'
  },
  {
    id: 'financial',
    icon: BadgeDollarSign,
    title: 'Financial Advisory',
    description: 'Strategic financial guidance to optimize performance and drive sustainable growth.',
    features: [
      'Financial Strategy Development',
      'Investment Portfolio Management',
      'Startup Financing Services',
      'Capital Structure Optimization'
    ],
    benefits: ['Value Creation', 'Growth', 'Optimization'],
    color: 'from-amber-500 to-amber-600',
    category: 'financial'
  },
  {
    id: 'solutions-selling',
    icon: Handshake,
    title: 'Solutions Selling',
    description: 'Transform your sales approach with our comprehensive solutions selling methodology.',
    features: [
      'Sales Process Transformation',
      'Value Proposition Development',
      'Sales Enablement Tools',
      'Solution-focused Training'
    ],
    benefits: ['Higher Win Rates', 'Larger Deals', 'Strategic Partnerships'],
    color: 'from-purple-500 to-purple-600',
    category: 'solutions-selling'
  }
];