import React from 'react';
import { FileText, CheckCircle, AlertCircle, HelpCircle } from 'lucide-react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <FileText className="h-12 w-12 text-blue-600 mx-auto mb-4" />
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Terms of Service</h1>
          <p className="text-xl text-gray-600">
            Please read these terms carefully before using our services.
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
          <div className="prose prose-blue max-w-none">
            <h2 className="flex items-center text-2xl font-bold text-gray-900 mb-4">
              <CheckCircle className="h-6 w-6 mr-2 text-blue-600" />
              Agreement to Terms
            </h2>
            <p className="text-gray-600 mb-6">
              By accessing or using STS Worldwide's services, you agree to be bound by these Terms of Service and all applicable laws and regulations.
            </p>

            <h3 className="flex items-center text-xl font-semibold text-gray-900 mb-4">
              <AlertCircle className="h-6 w-6 mr-2 text-blue-600" />
              Use of Services
            </h3>
            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li>Services provided by STS Worldwide</li>
              <li>Client responsibilities</li>
              <li>Intellectual property rights</li>
              <li>Confidentiality obligations</li>
            </ul>

            <h3 className="flex items-center text-xl font-semibold text-gray-900 mb-4">
              <HelpCircle className="h-6 w-6 mr-2 text-blue-600" />
              Limitation of Liability
            </h3>
            <p className="text-gray-600 mb-6">
              STS Worldwide's liability is limited to the extent permitted by applicable law. We are not responsible for indirect, incidental, or consequential damages.
            </p>

            {/* Add more sections as needed */}
          </div>
        </div>

        <div className="text-center text-gray-600">
          <p>Last updated: March 15, 2024</p>
          <p>For questions about our terms of service, please contact legal@stsworldwide.com</p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;