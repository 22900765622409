import React, { useState } from 'react';
import { timelineEvents } from './Timeline/data/timelineEvents';
import TimelineEvent from './Timeline/TimelineEvent';
import TimelineModal from './Timeline/TimelineModal';
import { TimelineEvent as TimelineEventType } from './types';

const Timeline = () => {
  const [activeFilter, setActiveFilter] = useState<'all' | 'milestone' | 'award' | 'recognition'>('all');
  const [selectedEvent, setSelectedEvent] = useState<TimelineEventType | null>(null);

  const filteredEvents = timelineEvents.filter(
    event => activeFilter === 'all' || event.category === activeFilter
  );

  return (
    <section className="py-24 bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Journey</h2>
          <p className="text-xl text-gray-600">Four decades of excellence and innovation</p>
          
          <div className="flex flex-wrap justify-center gap-4 mt-8">
            {['all', 'milestone', 'award', 'recognition'].map((filter) => (
              <button
                key={filter}
                onClick={() => setActiveFilter(filter as any)}
                className={`px-6 py-2 rounded-full transition-all duration-300 ${
                  activeFilter === filter
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                }`}
              >
                {filter.charAt(0).toUpperCase() + filter.slice(1)}
              </button>
            ))}
          </div>
        </div>

        <div className="relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 w-1 h-full bg-blue-200" />

          <div className="space-y-16">
            {filteredEvents.map((event, index) => (
              <TimelineEvent
                key={event.id}
                event={event}
                index={index}
                onSelect={() => setSelectedEvent(event)}
              />
            ))}
          </div>
        </div>

        {selectedEvent && (
          <TimelineModal
            event={selectedEvent}
            onClose={() => setSelectedEvent(null)}
          />
        )}
      </div>
    </section>
  );
};

export default Timeline;