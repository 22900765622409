import { 
  TrendingUp, 
  Users, 
  Trophy, 
  Target,
  Globe2,
  Shield,
  Clock,
  Lightbulb,
  Briefcase,
  HeartHandshake,
  BarChart,
  Rocket,
  Zap,
  LineChart
} from 'lucide-react';

export const benefits = [
  {
    icon: TrendingUp,
    title: 'Accelerated Growth',
    description: 'Drive sustainable business growth through strategic initiatives',
    metrics: '35% Average Growth'
  },
  {
    icon: Globe2,
    title: 'Global Reach',
    description: 'Access to international markets and expertise',
    metrics: '10+ Countries'
  },
  {
    icon: Shield,
    title: 'Risk Management',
    description: 'Comprehensive risk assessment and mitigation',
    metrics: '40% Risk Reduction'
  },
  {
    icon: Users,
    title: 'Expert Team',
    description: 'Access to industry veterans and subject matter experts',
    metrics: '50+ Experts'
  },
  {
    icon: Clock,
    title: 'Fast Implementation',
    description: 'Rapid deployment of solutions with measurable results',
    metrics: '30% Faster Delivery'
  },
  {
    icon: Lightbulb,
    title: 'Innovation Focus',
    description: 'Cutting-edge solutions and innovative approaches',
    metrics: '25+ Innovation Awards'
  },
  {
    icon: BarChart,
    title: 'Data-Driven Insights',
    description: 'Analytics-powered decision making and strategy',
    metrics: '99% Accuracy Rate'
  },
  {
    icon: HeartHandshake,
    title: 'Client Success',
    description: 'Dedicated focus on achieving client objectives',
    metrics: '95% Client Satisfaction'
  },
  {
    icon: Rocket,
    title: 'Digital Excellence',
    description: 'Leading digital transformation expertise',
    metrics: '100+ Digital Projects'
  },
  {
    icon: LineChart,
    title: 'ROI Optimization',
    description: 'Maximizing return on investment for clients',
    metrics: '40% Average ROI'
  }
];