import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Leader } from './types';

interface LeaderCardProps {
  leader: Leader;
  onClick: () => void;
}

const LeaderCard = ({ leader, onClick }: LeaderCardProps) => {
  return (
    <div className="group relative" onClick={onClick}>
      <div className="relative overflow-hidden rounded-2xl cursor-pointer">
        <img
          src={leader.image}
          alt={leader.name}
          className="w-full h-80 object-cover transform group-hover:scale-110 transition-transform duration-500"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        
        <div className="absolute inset-0 p-6 flex flex-col justify-end translate-y-4 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-300">
          <div className="text-white">
            <h3 className="text-xl font-semibold mb-1">{leader.name}</h3>
            <p className="text-gray-200 mb-2">{leader.role}</p>
            <div className="flex flex-wrap gap-2 mb-4">
              {leader.expertise.slice(0, 2).map((skill) => (
                <span key={skill} className="text-xs bg-white/20 px-2 py-1 rounded-full">
                  {skill}
                </span>
              ))}
            </div>
            <button className="inline-flex items-center text-sm text-blue-300 hover:text-blue-200">
              View Profile <ArrowRight className="ml-1 h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaderCard;