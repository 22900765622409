import React from 'react';
import { motion } from 'framer-motion';
import { 
  ArrowRight, 
  Search, 
  BarChart2, 
  Network, 
  Mail, 
  Shield,
  Target,
  BookOpen,
  Briefcase,
  CheckCircle,
  FileText,
  HandshakeIcon,
  Building2
} from 'lucide-react';

const MAProcess = () => {
  const sellSideSteps = [
    {
      icon: BarChart2,
      title: 'Comprehensive Business Analysis',
      description: 'In-depth evaluation of business metrics, market position, and growth potential'
    },
    {
      icon: FileText,
      title: 'We Structure the Sale',
      description: 'Strategic deal structuring to maximize value and optimize terms'
    },
    {
      icon: Network,
      title: 'Confidential Networking',
      description: 'Leveraging our extensive network to identify ideal buyers'
    },
    {
      icon: Mail,
      title: 'Confidential Marketing',
      description: 'Targeted outreach to qualified buyers while maintaining confidentiality'
    },
    {
      icon: Shield,
      title: 'Information Flow Control',
      description: 'Managed disclosure process protecting sensitive information'
    }
  ];

  const buySideSteps = [
    {
      icon: Target,
      title: 'Take Charge',
      description: 'Strategic planning and acquisition criteria definition'
    },
    {
      icon: BarChart2,
      title: 'Evaluation',
      description: 'Comprehensive target assessment and valuation'
    },
    {
      icon: BookOpen,
      title: 'Education',
      description: 'Deep dive into industry dynamics and opportunities'
    },
    {
      icon: Search,
      title: 'Search',
      description: 'Systematic identification of potential targets'
    },
    {
      icon: Briefcase,
      title: 'Offer',
      description: 'Strategic negotiation and deal structuring'
    },
    {
      icon: FileText,
      title: 'Due Diligence',
      description: 'Thorough investigation and risk assessment'
    },
    {
      icon: HandshakeIcon,
      title: 'Closing',
      description: 'Efficient deal execution and documentation'
    },
    {
      icon: Building2,
      title: 'Post Closing',
      description: 'Integration planning and synergy realization'
    }
  ];

  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold text-white mb-4"
          >
            Beyond M&A - What Next
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-xl text-blue-200"
          >
            <br></br>
            One of the most significant barriers business owners ask themselves, “What do I next Monday morning after I sell my business?” As part of our exit strategy consulting, we will help you devise a plan post exit. Through STS vast network, we have other investment opportunities which have great potential for you to consider.

Tax Consequences – Plan a ahead of sale<br></br><br></br>
            Comprehensive M&A expertise for both buyers and sellers
          </motion.p>
        </div>

        <div className="grid lg:grid-cols-2 gap-16">
          {/* Sell Side Process */}
          <div>
            <motion.h3
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="text-2xl font-bold text-white mb-8 flex items-center"
            >
              <span className="w-12 h-12 rounded-full bg-blue-600 flex items-center justify-center mr-4">
                <Building2 className="h-6 w-6 text-white" />
              </span>
              Our Acquisition Process – Sell Side
            </motion.h3>

            <div className="space-y-6">
              {sellSideSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="relative"
                >
                  <div className="bg-white/10 backdrop-blur-md p-6 rounded-xl hover:bg-white/20 transition-all duration-300">
                    <div className="flex items-start">
                      <div className="p-3 rounded-lg bg-blue-600/20">
                        <step.icon className="h-6 w-6 text-blue-400" />
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-semibold text-white mb-2">{step.title}</h4>
                        <p className="text-blue-200">{step.description}</p>
                      </div>
                    </div>
                  </div>
                  {index < sellSideSteps.length - 1 && (
                    <div className="absolute left-8 top-full h-6 w-px bg-blue-600/30" />
                  )}
                </motion.div>
              ))}
            </div>
          </div>

          {/* Buy Side Process */}
          <div>
            <motion.h3
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="text-2xl font-bold text-white mb-8 flex items-center"
            >
              <span className="w-12 h-12 rounded-full bg-blue-600 flex items-center justify-center mr-4">
                <HandshakeIcon className="h-6 w-6 text-white" />
              </span>
              Our Buying Process : Buy-side
            </motion.h3>

            <div className="grid md:grid-cols-2 gap-6">
              {buySideSteps.map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="bg-white/10 backdrop-blur-md p-6 rounded-xl hover:bg-white/20 transition-all duration-300"
                >
                  <div className="p-3 rounded-lg bg-blue-600/20 mb-4">
                    <step.icon className="h-6 w-6 text-blue-400" />
                  </div>
                  <h4 className="text-lg font-semibold text-white mb-2">{step.title}</h4>
                  <p className="text-blue-200">{step.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* Call to Action 
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <button className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-all duration-300">
            Start Your M&A Journey
            <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </motion.div>*/}
      </div>
    </section>
  );
};

export default MAProcess;