import React from 'react';

const LeadershipQuote = () => {
  return (
    <div className="relative mb-16">
      <div className="absolute inset-0 bg-blue-600 transform -skew-y-3" />
      <div className="relative max-w-4xl mx-auto px-4 py-16 text-center">
        <svg
          className="h-12 w-12 text-white/20 mx-auto mb-6"
          fill="currentColor"
          viewBox="0 0 32 32"
        >
          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
        </svg>
        <blockquote className="text-2xl font-medium text-white mb-6">
          "Our leadership team brings together diverse perspectives, deep expertise, and a shared commitment to driving transformational change for our clients and communities."
        </blockquote>
        <div className="text-white/80">
          Pundi Narasimham, CEO
        </div>
      </div>
    </div>
  );
};

export default LeadershipQuote;