import React from 'react';
import AboutHero from '../components/about/AboutHero';
import FoundersProfile from '../components/about/founders/FoundersProfile';
import WhoWeAre from '../components/about/WhoWeAre';
import MissionVision from '../components/about/MissionVision';
import Values from '../components/about/Values';
import Timeline from '../components/about/Timeline';
import LeadershipTeam from '../components/about/LeadershipTeam/LeadershipTeam';
import GlobalPresenceMap from '../components/global/GlobalPresenceMap';
import ClientImpact from '../components/impact/ClientImpact';

const About = () => {
  return (
    <div className="min-h-screen">
      <AboutHero />
      <FoundersProfile />
      <WhoWeAre />
      <MissionVision />
      <Values />
      <Timeline />
      <GlobalPresenceMap />
      <LeadershipTeam />
      <ClientImpact />
    </div>
  );
};

export default About;