import React from 'react';
import { X, ArrowRight } from 'lucide-react';
import { Service } from '../types';
import ServiceModalBenefits from './ServiceModalBenefits';
import ServiceModalFeatures from './ServiceModalFeatures';

interface ServiceModalProps {
  service: Service;
  onClose: () => void;
}

const ServiceModal: React.FC<ServiceModalProps> = ({ service, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header with close button */}
        <div className="sticky top-0 bg-white border-b border-gray-100 p-6 flex items-center justify-between">
          <div className="flex items-center">
            <div className={`p-3 rounded-xl bg-gradient-to-r ${service.color} text-white`}>
              <service.icon className="h-6 w-6" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 ml-4">{service.title}</h3>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close modal"
          >
            <X className="h-6 w-6 text-gray-500" />
          </button>
        </div>

        <div className="p-8">
          {/* Content */}
          <div className="mb-8">
            <p className="text-gray-600 text-lg leading-relaxed">{service.description}</p>
          </div>

          <ServiceModalBenefits benefits={service.benefits} />
          <ServiceModalFeatures service={service} />

          {/* Footer with close and action buttons */}
          <div className="flex flex-col sm:flex-row justify-between gap-4 mt-8 pt-8 border-t border-gray-100">
            <button className="flex-1 inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
              Schedule Consultation
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
            <button 
              onClick={onClose}
              className="flex-1 px-6 py-3 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;