import React from 'react';
import InvestorHero from '../components/investor/InvestorHero';
import FinancialHighlights from '../components/investor/FinancialHighlights';
import MarketOpportunity from '../components/investor/MarketOpportunity';
import CompetitiveAdvantage from '../components/investor/CompetitiveAdvantage';
import InvestmentHighlights from '../components/investor/InvestmentHighlights';
import InvestmentThesis from '../components/investor/InvestmentThesis';
import StrategicGrowth from '../components/investor/StrategicGrowth';
import GovernanceOverview from '../components/investor/GovernanceOverview';
import InvestorResources from '../components/investor/InvestorResources';

const Investor = () => {
  return (
    <div className="min-h-screen">
      <InvestorHero />
      <FinancialHighlights />
      <MarketOpportunity />
      <CompetitiveAdvantage />
      <InvestmentHighlights />
      <InvestmentThesis />
      <StrategicGrowth />
      <GovernanceOverview />
      <InvestorResources />
    </div>
  );
};

export default Investor;