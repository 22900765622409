import React from 'react';
import { Target, Shield, Award, Users, Lightbulb } from 'lucide-react';

const values = [
  {
    icon: Target,
    title: 'Vision-Driven Leadership',
    description: 'Guiding our organization with clear purpose and strategic foresight'
  },
  {
    icon: Shield,
    title: 'Ethical Excellence',
    description: 'Upholding the highest standards of integrity and professional conduct'
  },
  {
    icon: Award,
    title: 'Innovation Culture',
    description: 'Fostering an environment that encourages creative thinking and bold solutions'
  },
  {
    icon: Users,
    title: 'Collaborative Spirit',
    description: 'Working together across boundaries to achieve exceptional results'
  },
  {
    icon: Lightbulb,
    title: 'Continuous Learning',
    description: 'Committed to personal and professional growth at all levels'
  }
];

const LeadershipValues = () => {
  return (
    <div className="bg-gray-50 rounded-2xl p-8">
      <h3 className="text-2xl font-bold text-gray-900 text-center mb-8">
        Our Leadership Values
      </h3>
      <div className="grid md:grid-cols-3 lg:grid-cols-5 gap-6">
        {values.map((value, index) => (
          <div 
            key={index}
            className="text-center p-6 bg-white rounded-xl hover:shadow-lg transition-shadow"
          >
            <value.icon className="h-8 w-8 text-blue-600 mx-auto mb-4" />
            <h4 className="font-semibold text-gray-900 mb-2">{value.title}</h4>
            <p className="text-sm text-gray-600">{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeadershipValues;