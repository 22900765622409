import React from 'react';
import { Shield, Award, Users, Rocket } from 'lucide-react';

const CompetitiveAdvantage = () => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Competitive Advantages</h2>
          <p className="text-xl text-gray-600">Our unique positioning in the market</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: Shield,
              title: 'Proprietary Technology',
              description: 'Advanced AI/ML algorithms with 15+ patents',
              metric: '98% Accuracy Rate'
            },
            {
              icon: Award,
              title: 'Market Leadership',
              description: 'Top 3 position in key markets globally',
              metric: '#1 in APAC Region'
            },
            {
              icon: Users,
              title: 'Enterprise Relationships',
              description: 'Long-term partnerships with Fortune 500',
              metric: '95% Retention Rate'
            },
            {
              icon: Rocket,
              title: 'Innovation Pipeline',
              description: 'Continuous R&D investment',
              metric: '$100M+ Investment'
            }
          ].map((advantage, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-8">
              <advantage.icon className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{advantage.title}</h3>
              <p className="text-gray-600 mb-4">{advantage.description}</p>
              <div className="text-lg font-semibold text-blue-600">{advantage.metric}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CompetitiveAdvantage;