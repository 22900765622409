import React from 'react';

interface GradientButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const GradientButton = ({ children, onClick }: GradientButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="relative group px-8 py-3 rounded-full bg-gradient-to-r from-blue-600 to-violet-600 text-white font-medium
        hover:from-blue-700 hover:to-violet-700 transition-all duration-300 transform hover:scale-105"
    >
      <span className="relative z-10 flex items-center">
        {children}
      </span>
      <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-400 to-violet-400 blur opacity-50 
        group-hover:opacity-75 transition-opacity duration-300" />
    </button>
  );
};

export default GradientButton;