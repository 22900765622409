import { 
  Building2, 
  Handshake, 
  Landmark, 
  Globe2,
  Briefcase
} from 'lucide-react';
import { Industry } from '../types';

export const industries: Industry[] = [
  {
    icon: Building2,
    name: 'Private Equity & Investment',
    description: 'Strategic advisory for global investment decisions and portfolio optimization',
    expertise: [
      'M&A Due Diligence',
      'Portfolio Value Creation',
      'Exit Strategy Planning',
      'Growth Funding',
      'Post-merger Integration',
      'Operational Improvement',
      'Digital Value Creation',
      'Startup Funding'
    ],
    clients: [
      'Global PE Firms',
      'Sovereign Wealth Funds',
      'Investment Banks',
      'Family Offices'
    ],
    keyMetrics: {
      projects: '50+',
      clients: '10+',
      experts: '15+',
      satisfaction: '98%'
    },
    successStories: [
      {
        title: 'Digital Transformation of Portfolio Company',
        result: '3x EBITDA Growth',
        impact: 'Successfully transformed traditional manufacturer into digital leader'
      },
      {
        title: 'Cross-border M&A',
        result: '$2B+ Deal Value',
        impact: 'Led end-to-end acquisition strategy and integration'
      }
    ]
  },
  {
    icon: Handshake,
    name: 'Mergers & Acquisitions',
    description: 'End-to-end M&A advisory services from strategy through post-merger integration',
    expertise: [
      'Target Identification & Screening',
      'Due Diligence Management',
      'Deal Structuring & Valuation',
      'Negotiation Support',
      'Post-merger Integration',
      'Synergy Realization',
      'Cultural Integration',
      'Change Management'
    ],
    clients: [
      'Fortune 500 Companies',
      'Private Equity Firms',
      'Strategic Buyers',
      'Corporate Development Teams'
    ],
    keyMetrics: {
      projects: '100+',
      clients: '40+',
      experts: '50+',
      satisfaction: '96%'
    },
    successStories: [
      {
        title: 'Cross-Border Technology Acquisition',
        result: '$1.5B Deal Value',
        impact: 'Successfully merged two industry leaders across continents'
      },
      {
        title: 'Healthcare Industry Consolidation',
        result: '$800M+ in Synergies',
        impact: 'Integrated three regional players into national powerhouse'
      }
    ]
  },
  {
    icon: Landmark,
    name: 'Financial Services',
    description: 'Specialized M&A advisory for financial institutions and fintech companies',
    expertise: [
      'Growth Funding',
      'Fintech Acquisitions',
      'Asset Management Deals',
      'Regulatory Compliance',
      'Financial Due Diligence',
      'Valuation Services',
      'Integration Planning',
      'Risk Assessment'
    ],
    clients: [
      'Global Banks',
      'Insurance Companies',
      'Asset Managers',
      'Fintech Companies'
    ],
    keyMetrics: {
      projects: '75+',
      clients: '30+',
      experts: '40+',
      satisfaction: '97%'
    },
    successStories: [
      {
        title: 'Digital Bank Acquisition',
        result: '$3B Deal Value',
        impact: 'Created leading digital banking platform through strategic merger'
      },
      {
        title: 'Insurance Group Consolidation',
        result: '$5B+ Transaction',
        impact: 'Successfully merged major insurance providers across regions'
      }
    ]
  },
  {
    icon: Globe2,
    name: 'Cross-Border M&A',
    description: 'Specialized expertise in complex international mergers, acquisitions, and joint ventures',
    expertise: [
      'International Deal Structuring',
      'Cross-Cultural Integration',
      'Global Regulatory Compliance',
      'Tax-Efficient Structuring',
      'Currency Risk Management',
      'International Due Diligence',
      'Global PMI Strategy',
      'Joint Venture Formation'
    ],
    clients: [
      'Multinational Corporations',
      'Global Conglomerates',
      'International Investors',
      'Sovereign Wealth Funds'
    ],
    keyMetrics: {
      projects: '60+',
      clients: '25+',
      experts: '45+',
      satisfaction: '96%'
    },
    successStories: [
      {
        title: 'Asia-Europe Manufacturing Merger',
        result: '$4.5B Deal Value',
        impact: 'Created global manufacturing powerhouse through cross-continental merger'
      },
      {
        title: 'US-LATAM Market Expansion',
        result: '$2.8B Joint Venture',
        impact: 'Established major presence in Latin American markets through strategic partnership'
      }
    ]
  }
];