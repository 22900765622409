import React from 'react';
import { Industry } from '../types';

interface IndustryCardProps {
  industry: Industry;
  isActive: boolean;
  onClick: () => void;
}

const IndustryCard: React.FC<IndustryCardProps> = ({ industry, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`w-full text-left p-6 rounded-xl transition-all duration-300 ${
        isActive
          ? 'bg-blue-50 border-l-4 border-blue-600'
          : 'hover:bg-gray-50'
      }`}
    >
      <div className="flex items-center">
        <industry.icon className={`h-6 w-6 ${
          isActive ? 'text-blue-600' : 'text-gray-400'
        }`} />
        <span className={`ml-3 font-medium ${
          isActive ? 'text-blue-600' : 'text-gray-900'
        }`}>
          {industry.name}
        </span>
      </div>
    </button>
  );
};

export default IndustryCard;