import React from 'react';
import { Newspaper } from 'lucide-react';
import { TimelineEvent as TimelineEventType } from './types';
import { getCategoryColor } from './utils/categoryUtils';
import { motion } from 'framer-motion';

interface TimelineEventProps {
  event: TimelineEventType;
  index: number;
  onSelect: (event: TimelineEventType) => void;
}

const TimelineEvent: React.FC<TimelineEventProps> = ({ event, index, onSelect }) => {
  return (
    <div
      className={`relative flex items-center ${
        index % 2 === 0 ? 'justify-start' : 'justify-end'
      }`}
    >
      <div className={`w-5/12 ${index % 2 === 0 ? 'pr-12' : 'pl-12'}`}>
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
          onClick={() => onSelect(event)}
        >
          <div className="flex items-center mb-4">
            <div className={`p-2 rounded-lg ${getCategoryColor(event.category)} text-white`}>
              <event.icon className="h-5 w-5" />
            </div>
            <span className="ml-3 text-blue-600 font-bold">{event.year}</span>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">{event.title}</h3>
          <p className="text-gray-600 text-sm">{event.description}</p>
          {event.source && (
            <div className="mt-4 flex items-center text-sm text-gray-500">
              <Newspaper className="h-4 w-4 mr-2" />
              {event.source}
            </div>
          )}
        </motion.div>
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <div className={`w-4 h-4 ${getCategoryColor(event.category)} rounded-full`}>
          <div className="absolute w-8 h-8 bg-blue-100 rounded-full -left-2 -top-2 animate-pulse" />
        </div>
      </div>
    </div>
  );
};

export default TimelineEvent;