import React from 'react';
import { Target, Eye } from 'lucide-react';

const MissionVision = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div className="inline-flex p-3 rounded-xl bg-blue-100 text-blue-600 mb-6">
              <Target className="h-6 w-6" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Mission</h3>
            <p className="text-gray-600 leading-relaxed">
              To provide actionable insights, scalable strategies, and transformative solutions that enable organizations to lead with confidence in a fast-changing world.
            </p>
          </div>

          <div className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300">
            <div className="inline-flex p-3 rounded-xl bg-violet-100 text-violet-600 mb-6">
              <Eye className="h-6 w-6" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Vision</h3>
            <p className="text-gray-600 leading-relaxed">
              To be the trusted partner for global businesses seeking growth, sustainability, and impact, fostering a future of innovation, collaboration, and excellence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;