import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { partners } from './data/partners';
import { Globe2, TrendingUp, Users, Building2, ArrowRight, ChevronRight } from 'lucide-react';

const GlobalPartners = () => {
  const [selectedPartner, setSelectedPartner] = useState(partners[0]);
  const [hoveredMetric, setHoveredMetric] = useState<string | null>(null);

  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuMSkiIHN0cm9rZS13aWR0aD0iMSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-20" />
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute bg-blue-500 rounded-full"
            style={{
              width: Math.random() * 4 + 2,
              height: Math.random() * 4 + 2,
              left: Math.random() * 100 + '%',
              top: Math.random() * 100 + '%',
            }}
            animate={{
              y: [0, -30, 0],
              opacity: [0.2, 0.5, 0.2],
            }}
            transition={{
              duration: Math.random() * 3 + 2,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Header Section */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-flex items-center px-4 py-2 rounded-full bg-blue-500/20 text-blue-300 mb-6"
          >
            <Globe2 className="h-5 w-5 mr-2" />
            Global M&A Network
          </motion.div>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl font-bold text-white mb-4"
          >
            Strategic M&A Partnerships
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-xl text-blue-200 max-w-3xl mx-auto"
          >
            Access our global network of M&A specialists and strategic partners
          </motion.p>
        </div>

        {/* Partner Selection */}
        <div className="grid lg:grid-cols-5 gap-8 mb-12">
          {partners.map((partner) => (
            <motion.button
              key={partner.id}
              onClick={() => setSelectedPartner(partner)}
              whileHover={{ scale: 1.02 }}
              className={`p-6 rounded-2xl text-left transition-all duration-300 ${
                selectedPartner.id === partner.id
                  ? 'bg-blue-600/30 border-2 border-blue-400'
                  : 'bg-white/5 border-2 border-transparent hover:border-blue-400/50'
              }`}
            >
              <h3 className="text-lg font-semibold text-white mb-2">{partner.name}</h3>
              <p className="text-sm text-blue-200 mb-4">{partner.type}</p>
              <div className="flex items-center text-blue-300">
                Learn More <ChevronRight className="ml-1 h-4 w-4" />
              </div>
            </motion.button>
          ))}
        </div>

        {/* Selected Partner Details */}
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedPartner.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white/10 backdrop-blur-md rounded-3xl overflow-hidden"
          >
            <div className="grid lg:grid-cols-2 gap-8">
              {/* Partner Image */}
              <div className="relative h-full min-h-[400px]">
                <img
                  src={selectedPartner.image}
                  alt={selectedPartner.name}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                <div className="absolute bottom-8 left-8 right-8">
                  <div className="flex items-center text-blue-300 mb-4">
                    <Globe2 className="h-5 w-5 mr-2" />
                    {selectedPartner.regions.join(' • ')}
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    {selectedPartner.metrics.map((metric) => (
                      <motion.div
                        key={metric.label}
                        onMouseEnter={() => setHoveredMetric(metric.label)}
                        onMouseLeave={() => setHoveredMetric(null)}
                        className="group"
                      >
                        <motion.div
                          animate={{
                            scale: hoveredMetric === metric.label ? 1.05 : 1
                          }}
                          className="bg-white/10 backdrop-blur-sm p-4 rounded-xl"
                        >
                          <div className="text-2xl font-bold text-white mb-1">{metric.value}</div>
                          <div className="text-sm text-blue-200">{metric.label}</div>
                        </motion.div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Partner Details */}
              <div className="p-8">
                <h3 className="text-3xl font-bold text-white mb-4">{selectedPartner.name}</h3>
                <p className="text-lg text-blue-200 mb-8">{selectedPartner.description}</p>

                {/* Capabilities */}
                <div className="mb-8">
                  <h4 className="text-lg font-semibold text-white mb-4">Core Capabilities</h4>
                  <div className="grid grid-cols-2 gap-4">
                    {selectedPartner.capabilities.map((capability) => (
                      <motion.div
                        key={capability}
                        whileHover={{ scale: 1.02 }}
                        className="flex items-center p-3 bg-white/5 rounded-lg"
                      >
                        <div className="h-2 w-2 bg-blue-400 rounded-full mr-3" />
                        <span className="text-blue-200">{capability}</span>
                      </motion.div>
                    ))}
                  </div>
                </div>

                {/* Key Partners */}
                <div>
                  <h4 className="text-lg font-semibold text-white mb-4">Key Partners</h4>
                  <div className="grid grid-cols-3 gap-4">
                    {selectedPartner.partners.map((name) => (
                      <motion.div
                        key={name}
                        whileHover={{ scale: 1.02 }}
                        className="bg-white/5 p-4 rounded-xl"
                      >
                        <Building2 className="h-5 w-5 text-blue-400 mb-2" />
                        <div className="text-white">{name}</div>
                      </motion.div>
                    ))}
                  </div>
                </div>

                {/* Action Button 
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  className="mt-8 w-full flex items-center justify-center px-8 py-4 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-colors"
                >
                  Connect with {selectedPartner.name}
                  <ArrowRight className="ml-2 h-5 w-5" />
                </motion.button>*/}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

export default GlobalPartners;