export const VIEW_OPTIONS = ['timeline', 'overview'] as const;

export const FILTERS = ['all', 'milestone', 'award', 'recognition'] as const;

export const CATEGORY_COLORS = {
  milestone: {
    bg: 'bg-blue-500',
    text: 'text-blue-600',
    light: 'bg-blue-100'
  },
  award: {
    bg: 'bg-purple-500',
    text: 'text-purple-600',
    light: 'bg-purple-100'
  },
  recognition: {
    bg: 'bg-green-500',
    text: 'text-green-600',
    light: 'bg-green-100'
  }
} as const;