import React from 'react';
import { motion } from 'framer-motion';
import { Users, Globe2, Award, TrendingUp } from 'lucide-react';

const stats = [
  {
    icon: Users,
    value: '50+',
    label: 'Global Clients',
    color: 'bg-blue-100 text-blue-600'
  },
  {
    icon: Globe2,
    value: '10+',
    label: 'Countries Served',
    color: 'bg-violet-100 text-violet-600'
  },
  {
    icon: Award,
    value: '98%',
    label: 'Client Satisfaction',
    color: 'bg-indigo-100 text-indigo-600'
  },
  {
    icon: TrendingUp,
    value: '40+',
    label: 'Years Experience',
    color: 'bg-purple-100 text-purple-600'
  }
];

const SolutionsStats = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-16">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: index * 0.1 }}
          className="bg-white rounded-2xl p-6 shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <div className={`inline-flex p-3 rounded-xl ${stat.color} mb-4`}>
            <stat.icon className="h-6 w-6" />
          </div>
          <div className="text-3xl font-bold text-gray-900 mb-2">{stat.value}</div>
          <div className="text-sm text-gray-600">{stat.label}</div>
        </motion.div>
      ))}
    </div>
  );
};

export default SolutionsStats;