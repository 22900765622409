import React from 'react';
import { Target, Shield, TrendingUp, Globe2 } from 'lucide-react';

const InvestmentThesis = () => {
  return (
    <section className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Investment Thesis</h2>
          <p className="text-xl text-gray-600">Compelling reasons to invest in our future</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            {
              icon: Target,
              title: 'Market Leadership',
              description: 'Dominant position in key markets with significant growth potential'
            },
            {
              icon: Shield,
              title: 'Strong Fundamentals',
              description: 'Robust balance sheet with consistent cash flow generation'
            },
            {
              icon: TrendingUp,
              title: 'Growth Strategy',
              description: 'Clear path to sustainable growth through strategic initiatives'
            },
            {
              icon: Globe2,
              title: 'Global Presence',
              description: 'Diversified revenue streams across major global markets'
            }
          ].map((item, index) => (
            <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
              <item.icon className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{item.title}</h3>
              <p className="text-gray-600">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default InvestmentThesis;