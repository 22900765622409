import { Leader } from '../types';

export const leaders: Leader[] = [
  {
    name: 'Mr. Pundi L Narasimham',
    role: 'Chief Executive Officer',
    image: 'https://www.stsworldwide.us/img/STS-Worldwide-Founder-and-Chief-Mentor-Pundi-Narasimham.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Innovation through collaboration',
    expertise: ['Strategic Planning', 'Digital Transformation', 'Global Markets'],
    achievements: [
      'Led trade delegation from Georgia to Silicon Valley of India',
      'Nominated as International Business Development Representative',
      'Established US County Trade Office in Bangalore'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'pundi@stsworldwide.us',
    bio: 'US Citizen and a serial entrepreneur from 1982, having built and sold many companies in the last few decades in North America and Asia Pacific region covering India, Malaysia and Philippines.'
  },
  {
    name: 'Deepak Narasimham',
    role: 'Chief Strategy Officer',
    image: 'https://www.stsworldwide.us/img/STS-Worldwide-Founder-Deepak-Narasimham.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Turn challenges into opportunities',
    expertise: ['M&A Advisory', 'Corporate Strategy', 'Risk Management'],
    achievements: [
      'Led multiple merger and acquisition deals since 2006',
      'Expanded company operations globally',
      'Key role in strategic partnerships'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'deepak@stsworldwide.us',
    bio: 'Deepak Narasimham is a seasoned IT professional with a diversified background in technology and finance.'
  },

  {
    name: 'Christopher Kuhn',
    role: 'CFO/Finance/Lending expert',
    image: 'https://www.stsworldwide.us/img/christopher-kuhn.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Turn challenges into opportunities',
    expertise: ['M&A Advisory', 'Corporate Strategy', 'Accounting'],
    achievements: [
      'Developed new Middle Market segment',
      'Organized M&A Integration session for $60MM entity resulting in large M&A Integration',
      'Results-oriented finance executive with demonstrated record of accomplishment'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'ckuhn@stsworldwide.us',
    bio: 'Versatile business leader bringing 25 years’ experience as an accomplished Commercial Banker in both credit and sales.'
  },

  
  {
    name: 'Ruby Mishra',
    role: 'Professional Services',
    image: 'https://www.stsworldwide.us/img/ruby-mishra.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Turn challenges into opportunities',
    expertise: ['M&A Advisory', 'Corporate Strategy', 'Professional Services'],
    achievements: [
      'IT requirements for both Public Sector/ Government',
      'Expanded company operations globally',
      'Key role in strategic partnerships & Client Aquisition'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'ruby@stsworldwide.us',
    bio: 'IT Staffing- Professional Services Division at STS Staffing Group, NJ U.S.A.'
  },
  {
    name: 'Govind Gurrala',
    role: 'Managing Partner, CT, U.S.A',
    image: 'https://www.stsworldwide.us/img/govind-gurrala.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Results-driven transformation through technology',
    expertise: [
      'Project Management',
      'Digital Transformation',
      'Technology Consulting',
      'Process Implementation',
      'Team Leadership',
      'Customer Relationship Management'
    ],
    achievements: [
      'Leading Cigna\'s transformation/adoption efforts to newer technologies',
      '11 years as Director of Operations at UnitedHealth Group',
      '5 years at Tata Consultancy Services in leadership roles',
      'Successfully managed large/complex teams across the globe'
    ],
    previousRoles: [
      'Delivery Lead at Cigna',
      'Director of Operations at UnitedHealth Group',
      'Customer Relationship Manager at TCS',
      'Technical Lead at TCS',
      'Implementation Manager at TCS'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'govind@stsworldwide.us',
    bio: 'Govind Gurrala is a resourceful and team-oriented business consultant with over 23 years of domestic and international experience in operations, project/program management and service delivery. He is results proven, customer-focused professional with extensive knowledge in customer relationship management, technology consulting, process implementation and managing large/complex teams across the globe.'
  },
  {
    name: 'Prathap Singh',
    role: 'Honorary M&A - Asia Pacific Strategic Advisor',
    image: 'https://www.stsworldwide.us/img/Prathap-Singh.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Global vision, local execution',
    expertise: [
      'International Trade',
      'M&A Advisory',
      'Government Relations',
      'Strategic Partnerships'
    ],
    achievements: [
      'Led numerous US-India trade delegations',
      'Director of multiple NASDAQ & BSE listed companies',
      'Vice Chairman US Indian American Chamber of Commerce',
      'Advisor to $2B state-owned Financial Corporation'
    ],
    previousRoles: [
      'Director, Chairman Audit committee & Corporate Governance of NASDAQ listed companies',
      'Regional Director and Vice Chairman US Indian American Chamber of Commerce',
      'Director Marketing - Mergers & Acquisitions for a Law firm in New York',
      'Director, Healthcare Company, North Sydney, Australia'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'pat@stsworldwide.us',
    bio: '58 year old US management professional travelled extensively across the world. Spending 6 months in US & 6 months in India for over 2 decades.'
  },
  {
    name: 'Sethu Bhaskaran',
    role: 'Managing Partner, Atlanta, GA U.S.A',
    image: 'https://www.stsworldwide.us/img/sethu-bhaskaran.jpg?auto=format&fit=crop&q=80',
    philosophy: 'Technology as a vehicle for business transformation',
    expertise: [
      'IT Architecture',
      'Digital Transformation',
      'Strategic Solutions',
      'Product Development'
    ],
    achievements: [
      'Led IT transformations for Fortune 500 companies',
      'Successfully implemented offshore operation model',
      'Established private mentorship program',
      'Advisory consultant for CEOs and startup products'
    ],
    previousRoles: [
      'IT Architect at Citi',
      'IT Architect at US Bank',
      'IT Architect at T. Rowe Price',
      'IT Architect at Global Payments'
    ],
    linkedin: '#',
    twitter: '#',
    email: 'sethu@stsworldwide.us',
    bio: 'Sethu Bhaskaran has 20 plus Years in Technology with a deep dive attitude, expertise in learning the business background to use technology as a vehicle to achieve the desired results.'
  }
];