import { CaseStudy } from '../types';

export const caseStudies: CaseStudy[] = [
  {
    title: 'Global Market Expansion Strategy',
    category: 'Market Expansion',
    client: 'TechVision Solutions',
    challenge: 'Expanding into APAC markets while maintaining operational efficiency',
    solution: 'Developed comprehensive market entry strategy and local partnership network',
    results: {
      revenue: '+200% Growth',
      markets: '15 New Markets',
      timeline: '18 Months'
    },
    impact: [
      'Successfully entered 15 new markets across APAC',
      'Established strategic partnerships with local leaders',
      'Built scalable operational infrastructure',
      'Achieved revenue targets ahead of schedule'
    ],
    testimonial: {
      quote: "STS Worldwide's strategic guidance was instrumental in our successful APAC expansion.",
      author: "Sarah Chen",
      role: "CEO, TechVision Solutions"
    },
    image: "https://www.stsworldwide.us/img/photo-1460925895917-afdab827c52f.avif?auto=format&fit=crop&q=80"
  },
  {
    title: "Digital Transformation Excellence",
    category: "Digital Transformation",
    client: "Global Logistics Co.",
    challenge: "Modernizing legacy systems and improving operational efficiency",
    solution: "Implemented end-to-end digital transformation with AI-powered solutions",
    results: {
      efficiency: "+45% Efficiency",
      savings: "$2.5M Annual Savings",
      automation: "80% Process Automation"
    },
    impact: [
      'Automated 80% of manual processes',
      'Reduced operational costs by $2.5M annually',
      'Improved customer satisfaction by 40%',
      'Decreased processing time by 60%'
    ],
    testimonial: {
      quote: "The digital transformation led by STS has revolutionized our operations.",
      author: "Michael Rodriguez",
      role: "CTO, Global Logistics Co."
    },
    image: "https://www.stsworldwide.us/img/photo-1451187580459-43490279c0fa.avif?auto=format&fit=crop&q=80"
  },
  {
    title: "Optimizing Working Capital Management",
    category: "Financial Advisory",
    client: "Manufacturing Plus",
    challenge: "Improving cash flow and working capital efficiency",
    solution: "Implemented advanced working capital management strategies",
    results: {
      reduction: "25% CCC Reduction",
      capital: "$2M Freed Capital",
      efficiency: "35% Process Improvement"
    },
    impact: [
      'Reduced cash conversion cycle by 25%',
      'Freed up $2M in working capital',
      'Optimized inventory management',
      'Improved supplier relationships'
    ],
    testimonial: {
      quote: "STS's financial advisory services transformed our working capital management.",
      author: "Emma Thompson",
      role: "CFO, Manufacturing Plus"
    },
    image: "https://www.stsworldwide.us/img/photo-1590283603385-17ffb3a7f29f.avif?auto=format&fit=crop&q=80"
  },
  {
    title: "Supply Chain Transformation",
    category: "Operations",
    client: "Retail Distribution Co.",
    challenge: "Modernizing supply chain operations and reducing costs",
    solution: "Implemented digital supply chain transformation program",
    results: {
      turnover: "+40% Inventory Turnover",
      costs: "30% Logistics Costs",
      efficiency: "50% Time Reduction"
    },
    impact: [
      'Improved inventory turnover by 40%',
      'Reduced logistics costs by 30%',
      'Enhanced end-to-end visibility',
      'Optimized distribution network'
    ],
    testimonial: {
      quote: "The supply chain transformation has given us a competitive edge in the market.",
      author: "David Wilson",
      role: "COO, Retail Distribution Co."
    },
    image: "https://www.stsworldwide.us/img/photo-1587293852726-70cdb56c2866.avif?auto=format&fit=crop&q=80"
  }
];