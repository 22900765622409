import React, { useState } from 'react';
import { services } from './data/services';
import ServiceCard from './components/ServiceCard';
import ServiceDetails from './components/ServiceDetails';
import ServiceModal from './modals/ServiceModal';

const ServicesList = () => {
  const [activeService, setActiveService] = useState(services[0]);
  const [showModal, setShowModal] = useState(false);

  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-3 gap-12">
          {/* Service Navigation */}
          <div className="lg:col-span-1 space-y-4">
            {services.map((service) => (
              <ServiceCard
                key={service.id}
                service={service}
                isActive={activeService.id === service.id}
                onClick={() => setActiveService(service)}
              />
            ))}
          </div>

          {/* Service Details */}
          <div className="lg:col-span-2">
            <ServiceDetails
              service={activeService}
              onLearnMore={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>

      {/* Service Modal */}
      {showModal && (
        <ServiceModal
          service={activeService}
          onClose={() => setShowModal(false)}
        />
      )}
    </section>
  );
};

export default ServicesList;