import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Award, MapPin, Briefcase, GraduationCap } from 'lucide-react';
import { Founder } from './types';

interface FounderCardProps {
  founder: Founder;
  reverse?: boolean;
}

const FounderCard: React.FC<FounderCardProps> = ({ founder, reverse }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`bg-white rounded-2xl shadow-lg overflow-hidden transform hover:shadow-xl transition-all duration-300 ${
      reverse ? 'md:flex-row-reverse' : ''
    }`}>
      <div className="grid md:grid-cols-3 gap-8 p-8">
        <div className="md:col-span-1">
          <div className="relative aspect-square rounded-xl overflow-hidden mb-6">
            <img
              src={founder.image}
              alt={founder.name}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="space-y-4">
            <div className="flex items-center text-gray-600">
              <Briefcase className="h-5 w-5 mr-2 text-blue-600" />
              {founder.role}
            </div>
            {founder.location && (
              <div className="flex items-center text-gray-600">
                <MapPin className="h-5 w-5 mr-2 text-blue-600" />
                {founder.location}
              </div>
            )}
          </div>
        </div>

        <div className="md:col-span-2">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">{founder.name}</h3>
          
          {founder.education && (
            <div className="flex items-center mb-4 text-gray-600">
              <GraduationCap className="h-5 w-5 mr-2 text-blue-600" />
              {founder.education}
            </div>
          )}

          <div className={`prose prose-blue max-w-none ${!isExpanded && 'line-clamp-4'}`}>
            {founder.bio.split('\n').map((paragraph, index) => (
              <p key={index} className="text-gray-600 mb-4">{paragraph}</p>
            ))}
          </div>

          {founder.achievements && (
            <div className={`mt-6 ${!isExpanded && 'hidden'}`}>
              <h4 className="text-lg font-semibold text-gray-900 mb-4">Key Achievements</h4>
              <div className="grid sm:grid-cols-2 gap-4">
                {founder.achievements.map((achievement, index) => (
                  <div key={index} className="flex items-start">
                    <Award className="h-5 w-5 text-blue-600 mr-2 mt-1 flex-shrink-0" />
                    <span className="text-gray-600">{achievement}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="mt-6 flex items-center text-blue-600 hover:text-blue-700 transition-colors"
          >
            {isExpanded ? (
              <>
                Show Less <ChevronUp className="ml-2 h-5 w-5" />
              </>
            ) : (
              <>
                Read More <ChevronDown className="ml-2 h-5 w-5" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FounderCard;