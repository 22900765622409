import React from 'react';
import { motion } from 'framer-motion';
import FounderCard from './FounderCard';
import FounderStats from './FounderStats';
import FounderQuotes from './FounderQuotes';
import { founders } from './foundersData';

const FoundersProfile = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Visionary Leaders</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Meet the founders who transformed an idea into a global enterprise, leading with innovation and integrity
          </p>
        </motion.div>

        <FounderStats />
        
        <div className="mt-16 space-y-16">
          {founders.map((founder, index) => (
            <motion.div
              key={founder.id}
              initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <FounderCard 
                founder={founder} 
                reverse={index % 2 !== 0}
              />
            </motion.div>
          ))}
        </div>

        <FounderQuotes />
      </div>
    </section>
  );
};

export default FoundersProfile;