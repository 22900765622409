import React, { useState } from 'react';
import { Globe2, Users, Building2, BarChart } from 'lucide-react';

const regions = [
  {
    name: 'North America',
    offices: ['New York', 'San Francisco', 'Toronto', 'Chicago', 'Atlanta'],
    stats: {
      clients: '20+',
      projects: '15+',
      experts: '40+',
      growth: '35%'
    },
    keyMarkets: ['United States', 'Canada', 'Mexico'],
    industries: ['Technology', 'Financial Services', 'Healthcare', 'Energy']
  },
  {
    name: 'Europe',
    offices: ['London', 'Paris', 'Frankfurt', 'Amsterdam', 'Zurich'],
    stats: {
      clients: '5+',
      projects: '12+',
      experts: '18+',
      growth: '28%'
    },
    keyMarkets: ['UK', 'Germany', 'France', 'Netherlands', 'Switzerland'],
    industries: ['Manufacturing', 'Financial Services', 'Automotive', 'Energy']
  },
  {
    name: 'Middle East',
    offices: ['Dubai', 'Abu Dhabi', 'Riyadh', 'Doha', 'Kuwait City'],
    stats: {
      clients: '10+ Regional Leaders',
      projects: '8+',
      experts: '20+',
      growth: '45%'
    },
    keyMarkets: ['UAE', 'Saudi Arabia', 'Qatar', 'Kuwait', 'Bahrain'],
    industries: ['Oil & Gas', 'Infrastructure', 'Financial Services', 'Real Estate']
  },
  {
    name: 'Asia Pacific',
    offices: ['Singapore', 'Tokyo', 'Shanghai', 'Sydney', 'Mumbai'],
    stats: {
      clients: '18+ Market Leaders',
      projects: '13+',
      experts: '16+',
      growth: '42%'
    },
    keyMarkets: ['China', 'Japan', 'India', 'Singapore', 'Australia'],
    industries: ['Technology', 'Manufacturing', 'Financial Services', 'Retail']
  }
];

const GlobalPresenceMap = () => {
  const [activeRegion, setActiveRegion] = useState(regions[0]);
  const [hoveredOffice, setHoveredOffice] = useState('');

  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Global Reach, Local Impact
          </h2>
          <p className="text-xl text-blue-200 max-w-3xl mx-auto">
            Strategic presence in key markets worldwide, delivering transformative solutions
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Region Selection */}
          <div className="space-y-6">
            <div className="flex flex-wrap gap-4">
              {regions.map((region) => (
                <button
                  key={region.name}
                  onClick={() => setActiveRegion(region)}
                  className={`px-6 py-3 rounded-full transition-all duration-300 ${
                    activeRegion.name === region.name
                      ? 'bg-blue-600 text-white'
                      : 'bg-white/10 text-white hover:bg-white/20'
                  }`}
                >
                  {region.name}
                </button>
              ))}
            </div>

            <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8">
              <div className="grid grid-cols-2 gap-8 mb-8">
                <div className="flex items-center text-white">
                  <Building2 className="h-5 w-5 mr-2 text-blue-400" />
                  <span>{activeRegion.stats.clients}</span>
                </div>
                <div className="flex items-center text-white">
                  <BarChart className="h-5 w-5 mr-2 text-blue-400" />
                  <span>{activeRegion.stats.projects} Projects</span>
                </div>
                <div className="flex items-center text-white">
                  <Users className="h-5 w-5 mr-2 text-blue-400" />
                  <span>{activeRegion.stats.experts} Experts</span>
                </div>
                <div className="flex items-center text-white">
                  <Globe2 className="h-5 w-5 mr-2 text-blue-400" />
                  <span>{activeRegion.stats.growth} Growth</span>
                </div>
              </div>

              <div className="mb-8">
                <h3 className="text-lg font-semibold text-white mb-4">Key Markets</h3>
                <div className="flex flex-wrap gap-2">
                  {activeRegion.keyMarkets.map((market) => (
                    <span
                      key={market}
                      className="px-3 py-1 bg-white/10 rounded-full text-sm text-white"
                    >
                      {market}
                    </span>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold text-white mb-4">Core Industries</h3>
                <div className="grid grid-cols-2 gap-4">
                  {activeRegion.industries.map((industry) => (
                    <div
                      key={industry}
                      className="flex items-center text-white/80"
                    >
                      <span className="h-1.5 w-1.5 bg-blue-400 rounded-full mr-2" />
                      {industry}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Office Grid */}
          <div className="relative">
            <div className="aspect-square rounded-2xl overflow-hidden bg-white/10 backdrop-blur-md p-8">
              <div className="grid grid-cols-2 gap-4 h-full">
                {activeRegion.offices.map((office) => (
                  <div
                    key={office}
                    onMouseEnter={() => setHoveredOffice(office)}
                    onMouseLeave={() => setHoveredOffice('')}
                    className={`relative p-6 rounded-xl transition-all duration-300 cursor-pointer ${
                      hoveredOffice === office
                        ? 'bg-blue-600 transform scale-105'
                        : 'bg-white/5 hover:bg-white/10'
                    }`}
                  >
                    <Building2 className={`h-6 w-6 mb-4 ${
                      hoveredOffice === office ? 'text-white' : 'text-blue-400'
                    }`} />
                    <h4 className="text-lg font-semibold text-white mb-2">{office}</h4>
                    <p className="text-sm text-white/80">Regional Hub</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalPresenceMap;