import React from 'react';
import { ServiceMetric } from '../types';

interface ServiceMetricsProps {
  metrics: ServiceMetric[];
}

const ServiceMetrics: React.FC<ServiceMetricsProps> = ({ metrics }) => {
  return (
    <div className="grid grid-cols-4 gap-4">
      {metrics.map((metric, index) => (
        <div key={index} className="bg-gray-50 p-4 rounded-xl">
          <metric.icon className="h-6 w-6 text-blue-600 mb-2" />
          <div className="text-2xl font-bold text-gray-900">{metric.value}</div>
          <div className="text-sm text-gray-600">{metric.label}</div>
        </div>
      ))}
    </div>
  );
};

export default ServiceMetrics;