import React, { useState } from 'react';
import { industries } from './data/industries';
import IndustryCard from './components/IndustryCard';
import IndustryDetails from './components/IndustryDetails';

const IndustryExpertise = () => {
  const [activeIndustry, setActiveIndustry] = useState(industries[0]);

  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Industry-Leading Expertise
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Deep sector knowledge combined with global insights to deliver transformative results
          </p>
        </div>

        <div className="grid lg:grid-cols-3 gap-12">
          {/* Industry Navigation */}
          <div className="lg:col-span-1 space-y-4">
            {industries.map((industry) => (
              <IndustryCard
                key={industry.name}
                industry={industry}
                isActive={activeIndustry.name === industry.name}
                onClick={() => setActiveIndustry(industry)}
              />
            ))}
          </div>

          {/* Industry Details */}
          <div className="lg:col-span-2">
            <IndustryDetails industry={activeIndustry} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustryExpertise;