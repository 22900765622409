import React from 'react';
import { Check } from 'lucide-react';

interface ServiceModalBenefitsProps {
  benefits: string[];
}

const ServiceModalBenefits: React.FC<ServiceModalBenefitsProps> = ({ benefits }) => {
  return (
    <div className="mb-8">
      <h4 className="text-xl font-semibold text-gray-900 mb-4">Key Benefits</h4>
      <div className="grid md:grid-cols-2 gap-4">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className="flex items-start p-4 bg-gray-50 rounded-lg"
          >
            <Check className="h-5 w-5 text-green-500 mt-0.5 mr-3 flex-shrink-0" />
            <span className="text-gray-700">{benefit}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceModalBenefits;