import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';

const SolutionsHeader = () => {
  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="text-center mb-16"
    >
      <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 text-blue-600 mb-6">
        <Sparkles className="h-4 w-4 mr-2" />
        Tailored Solutions for Every Challenge
      </div>
      <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
        Comprehensive Business
        <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-violet-600">
          Solutions & Services
        </span>
      </h2>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        Transform your business with our innovative solutions designed to drive growth, efficiency, and success
      </p>
    </motion.div>
  );
};

export default SolutionsHeader;