import React from 'react';
import ServicesHero from '../components/services/ServicesHero';
import ServicesList from '../components/services/ServicesList';
import ServiceBenefits from '../components/services/ServiceBenefits';
import MAProcess from '../components/services/MAProcess';
import CaseStudyPreview from '../components/services/CaseStudyPreview';
import GlobalPartners from '../components/enterprise/GlobalPartners';
import ContactCTA from '../components/services/ContactCTA';

const Services = () => {
  return (
    <div className="min-h-screen">
      <ServicesHero />
      <ServicesList />
      <ServiceBenefits />
      <MAProcess />
      <CaseStudyPreview />
      <GlobalPartners />
      <ContactCTA />
    </div>
  );
};

export default Services;