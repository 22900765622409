import React from 'react';
import { Users, Award, Globe2, Briefcase } from 'lucide-react';

const stats = [
  {
    icon: Users,
    number: '250+',
    label: 'Global Team Members'
  },
  {
    icon: Award,
    number: '50+',
    label: 'Industry Awards'
  },
  {
    icon: Globe2,
    number: '20+',
    label: 'Countries Represented'
  },
  {
    icon: Briefcase,
    number: '1000+',
    label: 'Projects Delivered'
  }
];

const LeadershipStats = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
      {stats.map((stat, index) => (
        <div 
          key={index}
          className="text-center p-6 bg-blue-50 rounded-2xl hover:bg-blue-100 transition-colors"
        >
          <stat.icon className="h-8 w-8 text-blue-600 mx-auto mb-4" />
          <div className="text-3xl font-bold text-gray-900 mb-2">{stat.number}</div>
          <div className="text-sm text-gray-600">{stat.label}</div>
        </div>
      ))}
    </div>
  );
};

export default LeadershipStats;