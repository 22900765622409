import React from 'react';
import { Service } from '../types';
import { CheckCircle, Users, Clock, BarChart } from 'lucide-react';

interface ServiceModalFeaturesProps {
  service: Service;
}

const ServiceModalFeatures: React.FC<ServiceModalFeaturesProps> = ({ service }) => {
  // Service-specific features
  const getServiceFeatures = () => {
    switch (service.title) {
      case 'Strategy and Operations Consulting':
        return [
          'Strategic Planning & Execution',
          'Process Optimization',
          'Change Management',
          'Performance Metrics & KPIs',
          'Organizational Design',
          'Cost Optimization'
        ];
      case 'Market Entry & Expansion':
        return [
          'Market Analysis & Research',
          'Entry Strategy Development',
          'Partner Selection',
          'Risk Assessment',
          'Local Compliance',
          'Growth Planning'
        ];
      case 'Digital Transformation':
        return [
          'Technology Assessment',
          'Digital Strategy',
          'Cloud Migration',
          'Process Automation',
          'Data Analytics',
          'Digital Operating Model'
        ];
      case 'Risk Management and Compliance':
        return [
          'Risk Assessment Framework',
          'Compliance Programs',
          'Crisis Management',
          'Business Continuity',
          'Regulatory Reporting',
          'Control Implementation'
        ];
      case 'Financial Advisory Services':
        return [
          'Financial Planning',
          'Investment Strategy',
          'M&A Advisory',
          'Valuation Services',
          'Capital Structure',
          'Financial Risk Management'
        ];
      default:
        return [];
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h4 className="text-xl font-semibold text-gray-900 mb-4">Detailed Features</h4>
        <div className="grid md:grid-cols-2 gap-4">
          {getServiceFeatures().map((feature, index) => (
            <div
              key={index}
              className="flex items-center p-4 bg-gray-50 rounded-lg"
            >
              <CheckCircle className="h-5 w-5 text-blue-500 mr-3" />
              <span className="text-gray-700">{feature}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6">
        <div className="bg-blue-50 p-6 rounded-xl">
          <Users className="h-6 w-6 text-blue-600 mb-3" />
          <div className="text-2xl font-bold text-gray-900 mb-1">100+</div>
          <div className="text-sm text-gray-600">Expert Consultants</div>
        </div>
        <div className="bg-blue-50 p-6 rounded-xl">
          <Clock className="h-6 w-6 text-blue-600 mb-3" />
          <div className="text-2xl font-bold text-gray-900 mb-1">4-6 Weeks</div>
          <div className="text-sm text-gray-600">Average Timeline</div>
        </div>
        <div className="bg-blue-50 p-6 rounded-xl">
          <BarChart className="h-6 w-6 text-blue-600 mb-3" />
          <div className="text-2xl font-bold text-gray-900 mb-1">30%+</div>
          <div className="text-sm text-gray-600">Performance Improvement</div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModalFeatures;