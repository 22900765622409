import React, { useState } from 'react';
import { leaders } from './data/leaders';
import LeaderCard from './LeaderCard';
import LeaderModal from './LeaderModal';
import LeadershipStats from './LeadershipStats';
import LeadershipQuote from './LeadershipQuote';
import LeadershipValues from './LeadershipValues';

const LeadershipTeam: React.FC = () => {
  const [activeLeader, setActiveLeader] = useState(leaders[0]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Our Leadership Team
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Meet the visionaries driving innovation and excellence at STS Worldwide
          </p>
        </div>

        <LeadershipStats />
        <LeadershipQuote />

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          {leaders.map((leader) => (
            <LeaderCard
              key={leader.name}
              leader={leader}
              onClick={() => {
                setActiveLeader(leader);
                setIsModalOpen(true);
              }}
            />
          ))}
        </div>

        <LeadershipValues />

        <LeaderModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          leader={activeLeader}
        />
      </div>
    </section>
  );
};

export default LeadershipTeam;