import React from 'react';
import { ArrowUpRight, Rocket, Zap, Building } from 'lucide-react';

const StrategicGrowth = () => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Strategic Growth Initiatives</h2>
          <p className="text-xl text-gray-600">Driving sustainable growth through strategic investments</p>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {[
            {
              icon: Rocket,
              title: 'Digital Transformation',
              description: 'Investing in next-generation technologies',
              metrics: ['35% Digital Revenue', '$50M Investment', '2x Productivity']
            },
            {
              icon: Building,
              title: 'Market Expansion',
              description: 'Expanding presence in high-growth markets',
              metrics: ['10 New Markets', '45% Growth Rate', '3x Client Base']
            },
            {
              icon: Zap,
              title: 'Innovation Pipeline',
              description: 'Developing breakthrough solutions',
              metrics: ['5+ Patents', '5 New Products', '40% Success Rate']
            }
          ].map((initiative, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-8">
              <initiative.icon className="h-8 w-8 text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{initiative.title}</h3>
              <p className="text-gray-600 mb-6">{initiative.description}</p>
              
              <div className="space-y-3">
                {initiative.metrics.map((metric, idx) => (
                  <div key={idx} className="flex items-center text-gray-700">
                    <ArrowUpRight className="h-4 w-4 text-green-500 mr-2" />
                    {metric}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StrategicGrowth;