import React from 'react';
import { TrendingUp, Target, Globe2, Zap } from 'lucide-react';

const MarketOpportunity = () => {
  return (
    <section className="py-24 bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Market Opportunity</h2>
          <p className="text-xl text-gray-600">Positioned for exponential growth in expanding markets</p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Market Size & Growth */}
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Total Addressable Market</h3>
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Current Market Size</span>
                <span className="text-2xl font-bold text-blue-600">$120B</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">Expected Growth (2025)</span>
                <span className="text-2xl font-bold text-green-600">$180B</span>
              </div>
              <div className="flex items-center justify-between">
                <span className="text-gray-600">CAGR</span>
                <span className="text-2xl font-bold text-purple-600">22.5%</span>
              </div>
            </div>
          </div>

          {/* Growth Drivers */}
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Key Growth Drivers</h3>
            <div className="space-y-4">
              {[
                {
                  icon: Globe2,
                  title: 'Global Digital Transformation',
                  value: '$50B+ Opportunity'
                },
                {
                  icon: Target,
                  title: 'Enterprise AI Adoption',
                  value: '35% Annual Growth'
                },
                {
                  icon: Zap,
                  title: 'Cloud Infrastructure',
                  value: '40% Market Share'
                }
              ].map((driver, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center">
                    <driver.icon className="h-6 w-6 text-blue-600 mr-3" />
                    <span className="text-gray-900 font-medium">{driver.title}</span>
                  </div>
                  <span className="text-blue-600 font-semibold">{driver.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketOpportunity;