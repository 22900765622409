import React from 'react';
import { motion } from 'framer-motion';
import { Quote } from 'lucide-react';

const quotes = [
  {
    text: "Innovation and integrity have been our guiding principles since day one.",
    author: "Geetha Narasimham",
    role: "Co-Founder"
  },
  {
    text: "Our success is built on the foundation of trust and excellence in everything we do.",
    author: "Pundi Narasimham",
    role: "Founder & Chief Mentor"
  }
];

const FounderQuotes = () => {
  return (
    <div className="mt-24 grid md:grid-cols-2 gap-8">
      {quotes.map((quote, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: index * 0.2 }}
          className="bg-gradient-to-br from-blue-600 to-indigo-600 rounded-2xl p-8 text-white relative overflow-hidden"
        >
          <Quote className="absolute top-4 right-4 h-24 w-24 text-white/10" />
          <p className="text-xl font-medium mb-6 relative z-10">"{quote.text}"</p>
          <div className="relative z-10">
            <div className="font-semibold">{quote.author}</div>
            <div className="text-blue-200">{quote.role}</div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default FounderQuotes;