import { OfficeRegion } from './types';

export const officeRegions: OfficeRegion[] = [
  {
    name: 'United States',
    offices: [
      {
        city: 'Atlanta',
        state: 'Georgia',
        country: 'United States',
        address: 'STS Worldwide, 2475 Northwinds Parkway, Suite 200, Alpharetta',
        zip: '30009',
        phone: '+1 (404) 555-0123',
        email: 'atlanta.hq@stsworldwide.com',
        timezone: 'America/New_York',
        coordinates: { lat: 33.7863, lng: -84.3811 },
        amenities: ['Global Innovation Center', 'Executive Briefing Center', 'Research Lab', 'Training Academy'],
        team: {
          size: 12,
          leadership: 'Pundi Narasimham, Global CEO',
          specialties: ['Global Strategy', 'Digital Innovation', 'Enterprise Transformation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1575917649705-5b59aaa12e6b.avif?auto=format&fit=crop&q=80',
        isHeadquarters: true
      },
      {
        city: 'Austin',
        state: 'Texas',
        country: 'United States',
        address: '500 W 2nd Street',
        zip: '78701',
        phone: '+1 (512) 555-0123',
        email: 'austin@stsworldwide.com',
        timezone: 'America/Chicago',
        coordinates: { lat: 30.2672, lng: -97.7431 },
        amenities: ['Technology Hub', 'Innovation Lab', 'Client Center'],
        team: {
          size: 45,
          leadership: 'Austin, Regional Director',
          specialties: ['Technology', 'Digital Solutions', 'Innovation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1531218150217-54595bc2b934.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Princeton',
        state: 'New Jersey',
        country: 'United States',
        address: '1100 Cornwall Rd, Suite 101, Monmouth Junction, New Jersey',
        zip: '08540',
        phone: '+1 (609) 555-0123',
        email: 'princeton@stsworldwide.com',
        timezone: 'America/New_York',
        coordinates: { lat: 40.3573, lng: -74.6672 },
        amenities: ['Research Center', 'Financial Lab', 'Training Facility'],
        team: {
          size: 50,
          leadership: 'Ruby Mishra, Managing Director',
          specialties: ['Financial Services', 'Risk Management', 'Strategy']
        },
        image: 'https://www.stsworldwide.us/img/photo-1542887800-faca0261c9e1.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Hartford',
        state: 'Connecticut',
        country: 'United States',
        address: '777 Main Street',
        zip: '06103',
        phone: '+1 (860) 555-0123',
        email: 'hartford@stsworldwide.com',
        timezone: 'America/New_York',
        coordinates: { lat: 41.7658, lng: -72.6734 },
        amenities: ['Insurance Innovation Lab', 'Client Center', 'Training Facility'],
        team: {
          size: 10,
          leadership: 'Govind Guralla, Regional Director',
          specialties: ['Insurance', 'Risk Management', 'Digital Transformation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1605649487212-47bdab064df7.avif?auto=format&fit=crop&q=80'
      }
    ]
  },
  {
    name: 'Canada',
    offices: [
      {
        city: 'Vancouver',
        state: 'British Columbia',
        country: 'Canada',
        address: '1055 West Georgia Street',
        zip: 'V6E 3P3',
        phone: '+1 (604) 555-0123',
        email: 'vancouver@stsworldwide.com',
        timezone: 'America/Vancouver',
        coordinates: { lat: 49.2827, lng: -123.1207 },
        amenities: ['Innovation Hub', 'Client Experience Center', 'Digital Lab'],
        team: {
          size: 12,
          leadership: 'BN, Managing Director',
          specialties: ['Technology', 'Sustainability', 'Digital Innovation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1560814304-4f05b62af116.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Toronto',
        state: 'Ontario',
        country: 'Canada',
        address: '100 King Street West',
        zip: 'M5X 1E1',
        phone: '+1 (416) 555-0123',
        email: 'toronto@stsworldwide.com',
        timezone: 'America/Toronto',
        coordinates: { lat: 43.6532, lng: -79.3832 },
        amenities: ['Financial Center', 'Innovation Lab', 'Training Academy'],
        team: {
          size: 8,
          leadership: 'BN, Regional Director',
          specialties: ['Financial Services', 'Strategy', 'Digital Transformation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1517090504586-fde19ea6066f.avif?auto=format&fit=crop&q=80'
      }
    ]
  },
  {
    name: 'United Kingdom',
    offices: [
      {
        city: 'London',
        country: 'United Kingdom',
        address: '30 St Mary Axe',
        zip: 'EC3A 8BF',
        phone: '+44 20 7123 4567',
        email: 'london@stsworldwide.com',
        timezone: 'Europe/London',
        coordinates: { lat: 51.5144, lng: -0.0803 },
        amenities: ['European HQ', 'Innovation Centre', 'Client Experience Hub', 'Research Lab'],
        team: {
          size: 8,
          leadership: '  , European CEO',
          specialties: ['Financial Markets', 'Strategy', 'Digital Innovation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1513635269975-59663e0ac1ad.avif?auto=format&fit=crop&q=80'
      }
    ]
  },
  {
    name: 'India',
    offices: [
      {
        city: 'Chennai',
        state: 'Tamil Nadu',
        country: 'India',
        address: 'DLF IT Park, Mount Poonamallee Road',
        zip: '600089',
        phone: '+91 44 4567 8900',
        email: 'chennai@stsworldwide.com',
        timezone: 'Asia/Kolkata',
        coordinates: { lat: 13.0827, lng: 80.2707 },
        amenities: ['Technology Center', 'Innovation Lab', 'Training Academy'],
        team: {
          size: 15,
          leadership: 'Sampathkumar Padmanabhan, Managing Director',
          specialties: ['Technology', 'Digital Solutions', 'Analytics']
        },
        image: 'https://www.stsworldwide.us/img/photo-1582510003544-4d00b7f74220.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Bangalore',
        state: 'Karnataka',
        country: 'India',
        address: 'Prestige Tech Park, Outer Ring Road',
        zip: '560103',
        phone: '+91 80 4567 8900',
        email: 'bangalore@stsworldwide.com',
        timezone: 'Asia/Kolkata',
        coordinates: { lat: 12.9716, lng: 77.5946 },
        amenities: ['Innovation Hub', 'Digital Center', 'Research Lab'],
        team: {
          size: 20,
          leadership: 'Dhanunjay Machani, Technology Director',
          specialties: ['AI/ML', 'Cloud Solutions', 'Digital Transformation']
        },
        image: 'https://www.stsworldwide.us/img/photo-1596176530529-78163a4f7af2.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Hyderabad',
        state: 'Telangana',
        country: 'India',
        address: 'Raheja Mindspace, HITEC City',
        zip: '500081',
        phone: '+91 40 4567 8900',
        email: 'hyderabad@stsworldwide.com',
        timezone: 'Asia/Kolkata',
        coordinates: { lat: 17.4399, lng: 78.3981 },
        amenities: ['Technology Park', 'Innovation Center', 'Client Hub'],
        team: {
          size: 13,
          leadership: 'Sumanth Pokala, Regional Director',
          specialties: ['Enterprise Solutions', 'Digital Innovation', 'Analytics']
        },
        image: 'https://www.stsworldwide.us/img/photo-1582510003544-4d00b7f74220.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Mumbai',
        state: 'Maharashtra',
        country: 'India',
        address: 'Bandra Kurla Complex',
        zip: '400051',
        phone: '+91 22 4567 8900',
        email: 'mumbai@stsworldwide.com',
        timezone: 'Asia/Kolkata',
        coordinates: { lat: 19.0760, lng: 72.8777 },
        amenities: ['Financial Center', 'Innovation Hub', 'Client Experience Center'],
        team: {
          size: 9,
          leadership: 'Nikhil Morsawala, Financial Services Director',
          specialties: ['Financial Services', 'Strategy', 'Digital Solutions']
        },
        image: 'https://www.stsworldwide.us/img/photo-1529253355930-ddbe423a2ac7.avif?auto=format&fit=crop&q=80'
      }
    ]
  },
  {
    name: 'Asia Pacific',
    offices: [
      {
        city: 'Singapore',
        country: 'Singapore',
        address: 'Marina Bay Financial Centre',
        zip: '018983',
        phone: '+65 6789 0123',
        email: 'singapore@stsworldwide.com',
        timezone: 'Asia/Singapore',
        coordinates: { lat: 1.2839, lng: 103.8607 },
        amenities: ['APAC HQ', 'Innovation Center', 'Digital Hub', 'Client Experience Center'],
        team: {
          size: 2,
          leadership: 'Naga Siva, Singapore Director',
          specialties: ['Strategy', 'Digital Transformation', 'Financial Services']
        },
        image: 'https://www.stsworldwide.us/img/photo-1525625293386-3f8f99389edd.avif?auto=format&fit=crop&q=80'
      },
      {
        city: 'Kuala Lumpur',
        country: 'Malaysia',
        address: 'Petronas Twin Towers, KLCC',
        zip: '50088',
        phone: '+60 3 2345 6789',
        email: 'kualalumpur@stsworldwide.com',
        timezone: 'Asia/Kuala_Lumpur',
        coordinates: { lat: 3.1579, lng: 101.7120 },
        amenities: ['Innovation Lab', 'Digital Center', 'Training Academy'],
        team: {
          size: 10,
          leadership: 'Silan Periannan, Managing Partner',
          specialties: ['Digital Innovation', 'Strategy', 'Technology Solutions']
        },
        image: 'https://www.stsworldwide.us/img/photo-1596422846543-75c6fc197f07.avif?auto=format&fit=crop&q=80'
      }
    ]
  }
];