import React, { useState, useEffect } from 'react';
import { MapPin, Phone, Mail, Globe2, Building2, Clock, Users, Coffee, Wifi, Monitor } from 'lucide-react';
import { officeRegions } from './offices/officeData';
import RegionSelector from './offices/RegionSelector';

const GlobalOffices = () => {
  const [activeRegion, setActiveRegion] = useState(officeRegions[0].name);
  const [selectedOffice, setSelectedOffice] = useState(officeRegions[0].offices[0]);
  const [localTime, setLocalTime] = useState('');

  useEffect(() => {
    const updateLocalTime = () => {
      const time = new Date().toLocaleTimeString('en-US', {
        timeZone: selectedOffice.timezone,
        hour: '2-digit',
        minute: '2-digit'
      });
      setLocalTime(time);
    };

    updateLocalTime();
    const interval = setInterval(updateLocalTime, 1000);
    return () => clearInterval(interval);
  }, [selectedOffice.timezone]);

  const isOfficeOpen = () => {
    const hour = new Date().getHours();
    return hour >= 9 && hour < 18;
  };

  const currentRegion = officeRegions.find(region => region.name === activeRegion);

  return (
    <section className="py-24 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">
            Global Presence
          </h2>
          <p className="text-xl text-blue-200 max-w-3xl mx-auto">
            Strategic locations worldwide to serve your business needs
          </p>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {/* Region Selection */}
          <div className="lg:col-span-1">
            <RegionSelector
              regions={officeRegions}
              activeRegion={activeRegion}
              onRegionChange={(region) => {
                setActiveRegion(region);
                const newRegion = officeRegions.find(r => r.name === region);
                if (newRegion) {
                  setSelectedOffice(newRegion.offices[0]);
                }
              }}
            />
          </div>

          {/* Office Details */}
          <div className="lg:col-span-2">
            <div className="bg-white/10 backdrop-blur-lg rounded-2xl overflow-hidden">
              {/* Office Image and Header */}
              <div className="relative h-64">
                <img
                  src={selectedOffice.image}
                  alt={selectedOffice.city}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-6 left-6">
                  <div className="flex items-center mb-2">
                    {selectedOffice.isHeadquarters && (
                      <span className="px-3 py-1 bg-blue-500/20 text-blue-300 rounded-full text-sm mr-3">
                        Global Headquarters
                      </span>
                    )}
                  </div>
                  <h3 className="text-3xl font-bold text-white mb-2">
                    {selectedOffice.city}
                  </h3>
                  <div className="flex items-center text-blue-200">
                    <Clock className="h-5 w-5 mr-2" />
                    {localTime} Local Time
                    <span className={`ml-3 px-2 py-1 rounded-full text-xs ${
                      isOfficeOpen() ? 'bg-green-500/20 text-green-300' : 'bg-red-500/20 text-red-300'
                    }`}>
                      {isOfficeOpen() ? 'Open' : 'Closed'}
                    </span>
                  </div>
                </div>
              </div>

              {/* Office Grid */}
              <div className="p-8">
                <div className="grid md:grid-cols-2 gap-8 mb-8">
                  {/* Contact Information */}
                  <div>
                    <h4 className="text-lg font-semibold text-white mb-4">Contact Information</h4>
                    <div className="space-y-4 text-blue-200">
                      <div className="flex items-start">
                        <MapPin className="h-5 w-5 mr-3 mt-1" />
                        <div>
                          {selectedOffice.address}<br />
                          {selectedOffice.city}{selectedOffice.state && `, ${selectedOffice.state}`} {selectedOffice.zip}<br />
                          {selectedOffice.country}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Phone className="h-5 w-5 mr-3" />
                        {selectedOffice.phone}
                      </div>
                      <div className="flex items-center">
                        <Mail className="h-5 w-5 mr-3" />
                        {selectedOffice.email}
                      </div>
                    </div>
                  </div>

                  {/* Team Information */}
                  <div>
                    <h4 className="text-lg font-semibold text-white mb-4">Office Profile</h4>
                    <div className="space-y-4 text-blue-200">
                      <div className="flex items-center">
                        <Users className="h-5 w-5 mr-3" />
                        {selectedOffice.team.size}+ Professionals
                      </div>
                      <div>
                        <div className="font-semibold mb-2">Leadership:</div>
                        <div className="text-blue-300">{selectedOffice.team.leadership}</div>
                      </div>
                      <div>
                        <div className="font-semibold mb-2">Key Specialties:</div>
                        <div className="flex flex-wrap gap-2">
                          {selectedOffice.team.specialties.map((specialty) => (
                            <span
                              key={specialty}
                              className="px-3 py-1 bg-blue-500/20 rounded-full text-sm"
                            >
                              {specialty}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Office Selection */}
                {currentRegion && currentRegion.offices.length > 1 && (
                  <div className="mt-8 grid grid-cols-2 md:grid-cols-3 gap-4">
                    {currentRegion.offices.map((office) => (
                      <button
                        key={office.city}
                        onClick={() => setSelectedOffice(office)}
                        className={`p-4 rounded-xl text-left transition-all duration-300 ${
                          selectedOffice.city === office.city
                            ? 'bg-blue-600/20 border-l-4 border-blue-400'
                            : 'bg-white/5 hover:bg-white/10'
                        }`}
                      >
                        <Building2 className="h-5 w-5 text-blue-400 mb-2" />
                        <div className="font-medium text-white">{office.city}</div>
                        <div className="text-sm text-blue-200">
                          {office.state && `${office.state}, `}{office.country}
                        </div>
                      </button>
                    ))}
                  </div>
                )}

                {/* Amenities */}
                <div className="mt-8">
                  <h4 className="text-lg font-semibold text-white mb-4">Office Amenities</h4>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {selectedOffice.amenities.map((amenity) => (
                      <div
                        key={amenity}
                        className="flex items-center p-4 bg-white/5 rounded-xl"
                      >
                        <Monitor className="h-5 w-5 text-blue-400 mr-3" />
                        <span className="text-blue-200 text-sm">{amenity}</span>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Actions */}
                <div className="mt-8 flex justify-center space-x-4">
                  <button className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                    Schedule a Visit
                  </button>
                  <button className="px-6 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors">
                    Virtual Tour
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GlobalOffices;