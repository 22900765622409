import React from 'react';
import { X, Building2, TrendingUp, Users, BarChart, Globe2, Check } from 'lucide-react';

interface CaseStudyModalProps {
  study: any;
  onClose: () => void;
}

const CaseStudyModal: React.FC<CaseStudyModalProps> = ({ study, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        {/* Header Image */}
        <div className="relative h-64">
          <img 
            src={study.image}
            alt={study.title}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 bg-white/90 p-2 rounded-full hover:bg-white transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
          <div className="absolute bottom-6 left-6">
            <span className="px-3 py-1 bg-blue-600 rounded-full text-sm text-white mb-2 inline-block">
              {study.category}
            </span>
            <h2 className="text-3xl font-bold text-white mb-2">{study.title}</h2>
            <div className="flex items-center text-white/80">
              <Building2 className="h-5 w-5 mr-2" />
              {study.client}
            </div>
          </div>
        </div>

        <div className="p-8">
          {/* Key Metrics */}
          <div className="grid grid-cols-4 gap-6 mb-8">
            {Object.entries(study.results).map(([key, value]) => (
              <div key={key} className="bg-blue-50 p-4 rounded-xl">
                <TrendingUp className="h-6 w-6 text-blue-600 mb-2" />
                <div className="text-2xl font-bold text-gray-900">{value}</div>
                <div className="text-sm text-gray-600 capitalize">{key}</div>
              </div>
            ))}
          </div>

          {/* Challenge & Solution */}
          <div className="grid md:grid-cols-2 gap-8 mb-8">
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">The Challenge</h3>
              <p className="text-gray-600">{study.challenge}</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Our Solution</h3>
              <p className="text-gray-600">{study.solution}</p>
            </div>
          </div>

          {/* Impact */}
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Impact & Results</h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="space-y-4">
                {study.impact.map((item: string, index: number) => (
                  <div key={index} className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mt-1 mr-3 flex-shrink-0" />
                    <span className="text-gray-600">{item}</span>
                  </div>
                ))}
              </div>
              <div className="bg-gray-50 p-6 rounded-xl">
                <blockquote className="text-lg text-gray-600 italic mb-4">
                  "{study.testimonial.quote}"
                </blockquote>
                <div className="flex items-center">
                  <Users className="h-5 w-5 text-blue-600 mr-2" />
                  <div>
                    <div className="font-semibold">{study.testimonial.author}</div>
                    <div className="text-sm text-gray-500">{study.testimonial.role}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyModal;