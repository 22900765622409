import React from 'react';
import { Lightbulb, Shield, Award, Users, Target } from 'lucide-react';

const values = [
  {
    icon: Lightbulb,
    title: 'Innovation',
    description: 'We think beyond the ordinary to craft creative solutions.',
    color: 'from-blue-500 to-blue-600'
  },
  {
    icon: Shield,
    title: 'Integrity',
    description: 'Trust and transparency drive our relationships.',
    color: 'from-violet-500 to-violet-600'
  },
  {
    icon: Award,
    title: 'Excellence',
    description: 'We pursue the highest standards in everything we do.',
    color: 'from-indigo-500 to-indigo-600'
  },
  {
    icon: Users,
    title: 'Collaboration',
    description: 'Partnerships built on shared goals and success.',
    color: 'from-emerald-500 to-emerald-600'
  },
  {
    icon: Target,
    title: 'Impact',
    description: 'Every project delivers measurable and lasting results.',
    color: 'from-amber-500 to-amber-600'
  }
];

const Values = () => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">Our Values</h2>
          <p className="text-xl text-gray-600">The principles that guide everything we do</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <div 
              key={index}
              className="group relative bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden p-8"
            >
              <div className={`inline-flex p-3 rounded-xl bg-gradient-to-r ${value.color} mb-6 text-white`}>
                <value.icon className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">
                {value.title}
              </h3>
              <p className="text-gray-600">
                {value.description}
              </p>
              <div className="absolute inset-0 border-2 border-transparent group-hover:border-blue-500 rounded-2xl transition-colors duration-300" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Values;