import React from 'react';
import ContactHero from '../components/contact/ContactHero';
import ContactForm from '../components/contact/ContactForm';
import GlobalOffices from '../components/contact/GlobalOffices';
import ContactInfo from '../components/contact/ContactInfo';
import FAQ from '../components/contact/FAQ';

const Contact = () => {
  return (
    <div className="min-h-screen">
      <ContactHero />
      <div className="grid lg:grid-cols-2 gap-0">
        <ContactForm />
        <ContactInfo />
      </div>
      <GlobalOffices />
      <FAQ />
    </div>
  );
};

export default Contact;