import React, { useState } from 'react';
import { motion } from 'framer-motion';
import SolutionCard from './SolutionCard';
import { solutions } from './data/solutions';

const SolutionsList = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <div className="py-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <motion.div
            key={solution.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <SolutionCard
              solution={solution}
              isActive={activeIndex === index}
              onClick={() => setActiveIndex(activeIndex === index ? null : index)}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SolutionsList;